<template>
  <div class="row">
<!--    <div class="col-lg-12">-->
<!--      <div class="row">-->
<!--        <div class="col-lg-9">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-3 col-md-12 mr-h">-->
<!--              <div class="input-group input-group-sm">-->
<!--                <span class="input-group-addon">-->
<!--                  <i class="fa fa-search"></i>-->
<!--                </span>-->
<!--                <input-->
<!--                  id="search"-->
<!--                  name="search"-->
<!--                  :placeholder="$t('aps.search')"-->
<!--                  class="form-control filters__input"-->
<!--                  v-model="filters.query"-->
<!--                />-->
<!--                <span class="clear-input text-muted">-->
<!--                  <i class="fa fa-remove" @click="searchClear"></i>-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-12 mr-h">-->
<!--              <div class="location-sort-in-snmp-statistic">-->
<!--                &lt;!&ndash;              <div>&ndash;&gt;-->
<!--                &lt;!&ndash;              <small>{{ $t('general.filtersSort') }}</small>&ndash;&gt;-->
<!--                &lt;!&ndash;              </div>&ndash;&gt;-->
<!--                <div class="input-group input-group-sm">-->
<!--                  <span class="input-group-addon filters__field-icon">-->
<!--                    <i-->
<!--                      class="fa fa-sort-amount-desc text-primary icon-sort"-->
<!--                      v-if="currSortMode === 'desc'"-->
<!--                      @click="toggleSortMode()"-->
<!--                    ></i>-->
<!--                    <i-->
<!--                      class="fa fa-sort-amount-asc text-primary icon-sort"-->
<!--                      v-if="currSortMode === 'asc'"-->
<!--                      @click="toggleSortMode()"-->
<!--                    ></i>-->
<!--                  </span>-->
<!--                  <select class="form-control form-control-sm filters__field" v-model="sortByFilter">-->
<!--                    <option :selected="true" value="Name">{{ $t('PeriodicTasks.sortName') }}</option>-->
<!--                    <option :selected="true" value="ActionType">{{ $t('PeriodicTasks.sortActionType') }}</option>-->
<!--                    <option :selected="true" value="ObjectsCount">{{ $t('PeriodicTasks.sortObjectsCount') }}</option>-->
<!--                    <option :selected="true" value="NotifyType">{{ $t('PeriodicTasks.sortNotifyType') }}</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="col-lg-3 col-md-12 mr-h">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="input-group input-group-sm">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="fa fa-map-marker"&ndash;&gt;-->
<!--&lt;!&ndash;                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--&lt;!&ndash;              <select&ndash;&gt;-->
<!--&lt;!&ndash;                class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;                ref="locationsSelect"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.location"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="selectLocation"&ndash;&gt;-->
<!--&lt;!&ndash;              >&ndash;&gt;-->
<!--&lt;!&ndash;                <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}&ndash;&gt;-->
<!--&lt;!&ndash;                </option>&ndash;&gt;-->
<!--&lt;!&ndash;                <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                  {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                </option>&ndash;&gt;-->
<!--&lt;!&ndash;              </select>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class=" col-lg-2 d-flex p-0  align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;              <div&ndash;&gt;-->
<!--&lt;!&ndash;                class="d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;              >&ndash;&gt;-->
<!--&lt;!&ndash;                <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                  :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                  @change="selectLocation"&ndash;&gt;-->
<!--&lt;!&ndash;                />&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div&ndash;&gt;-->
<!--&lt;!&ndash;                class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;                <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--                          <div class="col-lg-3 col-md-12 mr-h input-group-for-location-select-in-monitoringPeriodicTasks&#45;&#45;filters">-->


<!--                                                                   <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-monitoringPeriodicTasks&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="selectLocation"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->


<!--              </div>-->




<!--          </div>-->
<!--          <div class="row mt-h">-->
<!--            <div class="col-12">-->
<!--              <div class="btn-group btn-group-sm float-left" data-toggle="button">-->
<!--                <label class="btn btn btn-outline-info" :class="{ active: filters.actionType == 'All' }">-->
<!--                  <input type="radio" name="typeFilter" @click="setFilter('actionType', 'All')" />-->
<!--                  {{ $t('general.all') }}-->
<!--                </label>-->
<!--                <label class="btn btn btn-outline-info" :class="{ active: filters.actionType == 'change_password' }">-->
<!--                  <input type="radio" name="typeFilter" @click="setFilter('actionType', 'change_password')" />-->
<!--                  {{ $t('PeriodicTasks.actionType.change_password') }}-->
<!--                </label>-->
<!--                <label class="btn btn btn-outline-info" :class="{ active: filters.actionType == 'reboot_point' }">-->
<!--                  <input type="radio" name="typeFilter" @click="setFilter('actionType', 'reboot_point')" />-->
<!--                  {{ $t('PeriodicTasks.actionType.reboot_point') }}-->
<!--                </label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row mt-h">-->
<!--            <div class="col-12">-->
<!--              <button v-if="isFiltersActive" class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--                <i class="fa fa-close"></i>-->
<!--                {{ $t('general.resetAllFilters') }}-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-lg-3 d-flex h-100 justify-content-end">-->
<!--          <div class="">-->
<!--            <button-->
<!--              v-if="!isDisable"-->
<!--              :disabled="isDisable || isShowOverTableSpinner"-->
<!--              type="button"-->
<!--              class="btn btn-success btn-md"-->
<!--              @click="openModalForCreating"-->
<!--            >-->
<!--              {{ $t('PeriodicTasks.createPeriodicTaskButton') }}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="col-lg-12 d-flex justify-content-end mb-h">
              <div class="">
            <button
              v-if="!isDisable"
              :disabled="isDisable || isShowOverTableSpinner"
              type="button"
              class="btn btn-info btn-md"
              @click="openModalForCreating"
            >
              {{ $t('PeriodicTasks.createPeriodicTaskButton') }}
            </button>
          </div>
    </div>

    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3 col-md-12 mb-h pr-0">
              <div class="input-group input-group-sm">
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
                <input
                  id="search"
                  name="search"
                  :placeholder="$t('aps.search')"
                  class="form-control filters__input"
                  v-model="filters.query"
                />
                <span class="clear-input text-muted">
                  <i class="fa fa-remove" @click="searchClear"></i>
                </span>
              </div>
            </div>
                                      <div class="col-lg-3 col-md-12 mb-h pr-0 input-group-for-location-select-in-monitoringPeriodicTasks--filters">


                                                                   <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                     :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-monitoringPeriodicTasks--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="selectLocation"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>


              </div>

            <div class="col-lg-3 col-md-12 mr-h mb-h pr-0">
              <div class="location-sort-in-snmp-statistic">
                <!--              <div>-->
                <!--              <small>{{ $t('general.filtersSort') }}</small>-->
                <!--              </div>-->
                <div class="input-group input-group-sm">
                  <span class="input-group-addon filters__field-icon">
                    <i
                      class="fa fa-sort-amount-desc text-primary icon-sort"
                      v-if="currSortMode === 'desc'"
                      @click="toggleSortMode()"
                    ></i>
                    <i
                      class="fa fa-sort-amount-asc text-primary icon-sort"
                      v-if="currSortMode === 'asc'"
                      @click="toggleSortMode()"
                    ></i>
                  </span>
                  <select class="form-control form-control-sm filters__field" v-model="sortByFilter">
                    <option :selected="true" value="Name">{{ $t('PeriodicTasks.sortName') }}</option>
                    <option :selected="true" value="ActionType">{{ $t('PeriodicTasks.sortActionType') }}</option>
                    <option :selected="true" value="ObjectsCount">{{ $t('PeriodicTasks.sortObjectsCount') }}</option>
                    <option :selected="true" value="NotifyType">{{ $t('PeriodicTasks.sortNotifyType') }}</option>
                  </select>
                </div>
              </div>
            </div>






          </div>
          <div class="row mt-h">
            <div class="col-12">
              <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn btn-outline-info" :class="{ active: filters.actionType == 'All' }">
                  <input type="radio" name="typeFilter" @click="setFilter('actionType', 'All')" />
                  {{ $t('general.all') }}
                </label>
                <label class="btn btn btn-outline-info" :class="{ active: filters.actionType == 'change_password' }">
                  <input type="radio" name="typeFilter" @click="setFilter('actionType', 'change_password')" />
                  {{ $t('PeriodicTasks.actionType.change_password') }}
                </label>
                <label class="btn btn btn-outline-info" :class="{ active: filters.actionType == 'reboot_point' }">
                  <input type="radio" name="typeFilter" @click="setFilter('actionType', 'reboot_point')" />
                  {{ $t('PeriodicTasks.actionType.reboot_point') }}
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-h">
            <div class="col-12">
              <button v-if="isFiltersActive" class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </button>
            </div>
          </div>
        </div>
<!--        <div class="col-lg-2 d-flex h-100 justify-content-end">-->
<!--          <div class="">-->
<!--            <button-->
<!--              v-if="!isDisable"-->
<!--              :disabled="isDisable || isShowOverTableSpinner"-->
<!--              type="button"-->
<!--              class="btn btn-success btn-md"-->
<!--              @click="openModalForCreating"-->
<!--            >-->
<!--              {{ $t('PeriodicTasks.createPeriodicTaskButton') }}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="col-lg-12 mt-1">
      <div class="">
        <div class="animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div v-if="isShowOverTableSpinner"><Loader-spinner></Loader-spinner></div>
                  <div class="card-header">
                    <i class="fa fa-bar-chart"></i>
                    {{ $t('PeriodicTasks.tableTitle') }}
                  </div>
                  <div class="card-block table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{ $t('PeriodicTasks.tableColumnName') }}</th>
                          <th>{{ $t('PeriodicTasks.tableColumnActionType') }}</th>
                          <th>{{ $t('PeriodicTasks.tableColumnObjects') }}</th>
                          <th>{{ $t('PeriodicTasks.tableColumnSpec') }}</th>
                          <th>{{ $t('PeriodicTasks.tableColumnNotifyType') }}</th>
                          <th>{{ $t('PeriodicTasks.tableColumnNotifyParameters') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(PeriodicTaskItem, index) in computedFilteredItems"
                          :data-index="index"
                          :data-id="PeriodicTaskItem.id"
                          :key="PeriodicTaskItem.id"
                          v-if="PeriodicTaskItem.id"
                          @click.stop="openModalForEdit(PeriodicTaskItem.id)"
                        >
                          <td class="responsive-row max-width-column-periodic-tasks-table">
                            <span v-tooltip="{ content: PeriodicTaskItem.name,
                             popperOptions: {
                                 modifiers: {
                                  preventOverflow: {
                                   boundariesElement: 'window'
                                  }
                                 }
                                }
                             }" v-if="PeriodicTaskItem.name">
                              {{ PeriodicTaskItem.name }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>

                          <td class="responsive-row max-width-column-periodic-tasks-table">
                            <span
                              v-tooltip="{ content: getActionTypeForTable(PeriodicTaskItem),
                               popperOptions: {
                                   modifiers: {
                                    preventOverflow: {
                                     boundariesElement: 'window'
                                    }
                                   }
                                  }
                               }"
                              v-if="PeriodicTaskItem.action"
                            >
                              {{ getActionTypeForTable(PeriodicTaskItem) }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>

                          <td class="">
                            <span
                              class="ml-q"
                              v-if="PeriodicTaskItem.ids && PeriodicTaskItem.action === 'reboot_point'"
                            >
                              {{ $t('PeriodicTasks.tableColumnCPEs') }}:
                              <span
                                v-tooltip.right="tooltipForPeriodicTaskItemCpeColumnInTable(PeriodicTaskItem.ids)"
                                class="text-info"
                              >
                                {{ cpesCount(PeriodicTaskItem.ids) }}
                              </span>
                            </span>
                            <span
                              class="ml-q"
                              v-else-if="PeriodicTaskItem.ids && PeriodicTaskItem.action === 'change_password'"
                            >
                              {{ $t('PeriodicTasks.tableColumnWlans') }}:
                              <span
                                v-tooltip.right="tooltipForPeriodicTaskItemWlansColumnInTable(PeriodicTaskItem.ids)"
                                class="text-info"
                              >
                                {{ wlansCount(PeriodicTaskItem.ids) }}
                              </span>
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>

                          <td class="responsive-row">
                            <span v-if="PeriodicTaskItem.spec">
                              {{ getSpecFieldForDIsplayInTableAsStringWithI18N(PeriodicTaskItem.spec) }}
                              <info
                                v-if="getSpecFieldInfoForDIsplayInTableAsStringWithI18N(PeriodicTaskItem.spec) !== ''"
                                :content="getSpecFieldInfoForDIsplayInTableAsStringWithI18N(PeriodicTaskItem.spec)"
                              />
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>

                          <td class="responsive-row max-width-column-periodic-tasks-table">
                            <span
                              v-tooltip="{ content: getNotifyTypeForTable(PeriodicTaskItem),
                               popperOptions: {
                                 modifiers: {
                                  preventOverflow: {
                                   boundariesElement: 'window'
                                  }
                                 }
                                }
                               }"
                              v-if="PeriodicTaskItem.notify"
                            >
                              {{ getNotifyTypeForTable(PeriodicTaskItem) }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>

                          <td v-if="PeriodicTaskItem.notify === 'telegram'">
                            <div>
                              <span>{{ $t('PeriodicTasks.notifySettingsForTableForTelegramToChatId') }}:</span>
                              <span class="periodic-tasks-notify-params-to-in-column">
                                {{ PeriodicTaskItem.notify_settings.chat_id }}
                              </span>
                            </div>
                          </td>
                          <td v-else-if="PeriodicTaskItem.notify === 'email'">
                            <div>
                              <span>{{ $t('PeriodicTasks.notifySettingsForTableForEmailToAddress') }}:</span>
                              <span class="periodic-tasks-notify-params-to-in-column">
                                {{ PeriodicTaskItem.notify_settings.to }}
                              </span>
                            </div>
                          </td>
                          <td v-else-if="PeriodicTaskItem.notify === 'mqtt'">
                            <div>
                              <span>{{ $t('PeriodicTasks.notifySettingsForTableForMQTTToTopic') }}:</span>
                              <span class="periodic-tasks-notify-params-to-in-column">
                                {{ PeriodicTaskItem.notify_settings.topic }}
                              </span>
                            </div>
                          </td>
                          <td v-else></td>
                        </tr>

                        <tr v-if="!computedFilteredItems.length">
                          <td colspan="20" class="no-data-row">
                            <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="Edit OR New Periodic Task"
      v-model="isEditOrNewPeriodicTaskModalShowing"
      class="modal-info"
      effect="fade/zoom"
    >
      <loader-spinner v-if="isShowOverCreateOrEditMOdalSpinner" centered></loader-spinner>
      <h4 slot="modal-title" class="modal-title">
        {{
          isCreatingNewPeriodicTaskModal
            ? $t('PeriodicTasks.modalHeaderForNew')
            : $t('PeriodicTasks.modalHeaderForEdit')
        }}
      </h4>
      <div>
        <!--        <pre>{{ updatedOrNewDITExport }}</pre>-->
        <div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="periodic-task-name">{{ $t('PeriodicTasks.modalNameCaption') }}</label>
                <input
                  type="text"
                  :data-vv-as="$t('PeriodicTasks.modalNameCaption')"
                  id="periodic-task-name"
                  :placeholder="$t('PeriodicTasks.modalNamePlaceholder')"
                  name="periodic-task-name"
                  :disabled="isDisable"
                  v-validate="'required|max:50'"
                  class="form-control"
                  :class="{ input: true, 'is-danger': errors.has('periodic-task-name') }"
                  v-model="updatedOrNewPeriodicTask.name"
                  @input="handleModalNameFieldInput"
                  data-vv-validate-on="none"
                />
                <span v-show="errors.has('periodic-task-name')" class="help is-danger">
                  {{ errors.first('periodic-task-name') }}
                </span>
              </div>

              <div class="form-group action-type-block-in-periodic-task-modal">
                <label for="actionType">{{ $t('PeriodicTasks.modalActionTypeCaption') }}</label>
                <select
                  id="actionType"
                  name="actionType"
                  v-model="updatedOrNewPeriodicTask.action"
                  class="form-control"
                >
                  <option
                    :disabled="isDisable"
                    v-for="type of PERIODIC_TASK_ACTION_TYPES"
                    :value="type"
                    :key="type"
                    :selected="updatedOrNewPeriodicTask.action === type"
                  >
                    {{ $t(`PeriodicTasks.actionType.${type}`) }}
                  </option>
                </select>
              </div>

              <transition name="fadeforcpefiltersblockinPeriodicTasksBlock" mode="out-in">
                <div v-if="isChangePasswordActionTypeSelected" :key="'isChangePasswordActionTypeSelected'">
                  <div class="filter mb-1 cpe-select-block">
                    <div>
                      <label for="dit-export-select-cpes">{{ $t('PeriodicTasks.modalWlansCaption') }}</label>
                      <Alert class="alert-info">
                        <template #text>
<!--                          <span class="mr-h">{{ $t('PeriodicTasks.onlyForWpaWpa2PersonalWLANSMsg') }}</span>-->
                          <span class="mr-h">{{ $t('PeriodicTasks.onlyForWpaPersonalWLANSMsg') }}</span>
                        </template>
                      </Alert>
                    </div>
                    <Loader-spinner-small centered v-if="isWlansListFiltering"></Loader-spinner-small>
                    <div>
                      <div
                        :class="{
                          'showing-filters-for-cpe-list-block-button': !filtersForWlanList.isFiltersForWlanListBlockShowing
                        }"
                        @click="toggleIsFiltersForWlanListBlockShowing"
                        role="button"
                        v-if="!isDisable"
                      >
                        <transition name="fadeforcpefiltersblockinPeriodicTasksBlock">
                          <i v-if="filtersForWlanList.isFiltersForWlanListBlockShowing" class="icon-arrow-down"></i>
                          <i v-else class="icon-arrow-right"></i>
                        </transition>
                        {{ $t('PeriodicTasks.filtersForWlanListSwitcherCaption') }}
                        &nbsp;
                        <transition name="fadeforcpefiltersblockinPeriodicTasksBlock">
                          <span
                            class="badge badge-light badge-success"
                            v-if="isSelectedNoDefaultsValuesInFiltersForWlanListANDFiltringEnabled"
                          >
                            {{ $t('PeriodicTasks.filtersForWlanListFiltringForWlanListIsEnabled') }} &nbsp;
                          </span>
                        </transition>
                        &nbsp;
                        <info :content="$t('PeriodicTasks.filtersForWlanListSwitcherInfoTooltip')" />
                      </div>
                    </div>
<!--                    <transition name="fadeforcpefiltersblockinPeriodicTasksBlock">-->
<!--                      <div-->
<!--                        v-if="filtersForWlanList.isFiltersForWlanListBlockShowing === true && !isDisable"-->
<!--                        class="filters-for-cpe-list ml-2 mt-1"-->
<!--                      >-->
<!--                        <div class="row">-->
<!--                          <div class="col-lg-12">-->
<!--                            <div class="form-group mb-q">-->
<!--                              <label for="locationForCpeFilter">-->
<!--                                {{ $t('PeriodicTasks.filtersForWlanListSwitcherLocationCaption') }}-->
<!--                              </label>-->
<!--                              <select-->
<!--                                v-model="filtersForWlanList.location"-->
<!--                                name="locationForCpeFilter"-->
<!--                                id="locationForCpeFilter"-->
<!--                                :class="{ 'select-disabled': !filtersForWlanList.isFiltersForWlanListEnabled }"-->
<!--                                class="form-control"-->
<!--                                :disabled="!filtersForWlanList.isFiltersForWlanListEnabled"-->
<!--                              >-->
<!--                                <option :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                                <option v-for="location in locationsList" :key="location.id" :value="location.id">-->
<!--                                  {{ location.name }}-->
<!--                                </option>-->
<!--                              </select>-->
<!--                            </div>-->
<!--                          </div>-->

<!--                          <div class="col-lg-6"></div>-->
<!--                        </div>-->
<!--                        <div class="row">-->
<!--                          <div class="col-lg-6">-->
<!--                            <div class="form-group mt-q d-flex justify-content-between">-->
<!--                              <button-->
<!--                                :disabled="filterdWlansListForModal.length === 0"-->
<!--                                @click="addAllFilteredWlansToSelected"-->
<!--                                style="font-weight: bold; white-space: normal; display: inline-flex;"-->
<!--                                type="button"-->
<!--                                class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"-->
<!--                              >-->
<!--                                <span style="font-size: 0.8em;">-->
<!--                                  {{ $t('PeriodicTasks.selectAllWlansButtonCaption') }}-->
<!--                                  <span>({{ this.filterdWlansListForModal.length }})</span>-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                          <div class="col-lg-6">-->
<!--                            <div class="form-group mt-q d-flex justify-content-between">-->
<!--                              <button-->
<!--                                @click.stop="clearSelectedWlansList"-->
<!--                                style="font-weight: bold; white-space: normal; display: inline-flex;"-->
<!--                                type="button"-->
<!--                                class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"-->
<!--                              >-->
<!--                                <span class="fa fa-close"></span>-->
<!--                                <span style="font-size: 0.8em;">-->
<!--                                  {{ $t('PeriodicTasks.cancelSelectAllWlansButtonCaption') }}-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="mt-0 mb-1">-->
<!--                          <transition name="fadeforcpefiltersblockinPeriodicTasksBlock" mode="out-in">-->
<!--                            <small-->
<!--                              v-if="isSelectedNoDefaultsValuesInFiltersForWlanListANDFiltringEnabled"-->
<!--                              :class="{ 'item-for-delete': !isDisable }"-->
<!--                              @click="clearFiltersForWlanList"-->
<!--                              class=""-->
<!--                            >-->
<!--                              <i v-if="!isDisable" class="fa fa-remove text-danger"></i>-->
<!--                              {{ $t('PeriodicTasks.filtersForCpeListResetFiltersButton') }}-->
<!--                            </small>-->
<!--                          </transition>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </transition>-->
                    <transition name="fadeforcpefiltersblockinPeriodicTasksBlock">
                      <div
                        v-if="filtersForWlanList.isFiltersForWlanListBlockShowing === true && !isDisable"
                        class="filters-for-cpe-list ml-2 mt-1"
                        style="position: relative"
                      >
                        <Loader-spinner-small centered v-if="showSpinnerOverWlansFilterInModal"></Loader-spinner-small>
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="form-group mb-q">
                              <label for="locationForCpeFilter">
                                {{ $t('PeriodicTasks.filtersForWlanListSwitcherLocationCaption') }}
                              </label>
                              <select
                                 @change="selectLocationInWlanFilterForModal"
                                v-model="filtersForWlanList.location"
                                name="locationForCpeFilter"
                                id="locationForCpeFilter"
                                :class="{ 'select-disabled': !filtersForWlanList.isFiltersForWlanListEnabled }"
                                class="form-control"
                                :disabled="!filtersForWlanList.isFiltersForWlanListEnabled"
                              >
                                <option :value="'All locations'">{{ $t('general.allLocations') }}</option>
                                <option v-for="location in locationsList" :key="location.id" :value="location.id">
                                  {{ location.name }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-6"></div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group mt-q d-flex justify-content-between">
                              <button
                                :disabled="wlanListLengthForSelectedInModalLocation === 0 || wlanListLengthForSelectedInModalLocation === undefined"
                                @click="addAllFilteredWlansToSelected"
                                style="font-weight: bold; white-space: normal; display: inline-flex;"
                                type="button"
                                class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"
                              >
                                <span style="font-size: 0.8em;">
                                  {{ $t('PeriodicTasks.selectAllWlansButtonCaption') }}
                                  <span>({{ wlanListLengthForSelectedInModalLocation }})</span>
                                </span>
                              </button>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group mt-q d-flex justify-content-between">
                              <button
                                @click.stop="clearSelectedWlansList"
                                style="font-weight: bold; white-space: normal; display: inline-flex;"
                                type="button"
                                class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"
                              >
                                <span class="fa fa-close"></span>
                                <span style="font-size: 0.8em;">
                                  {{ $t('PeriodicTasks.cancelSelectAllWlansButtonCaption') }}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="mt-0 mb-1">
                          <transition name="fadeforcpefiltersblockinPeriodicTasksBlock" mode="out-in">
                            <small
                              v-if="isSelectedNoDefaultsValuesInFiltersForWlanListANDFiltringEnabled"
                              :class="{ 'item-for-delete': !isDisable }"
                              @click="clearFiltersForWlanList"
                              class=""
                            >
                              <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                              {{ $t('PeriodicTasks.filtersForCpeListResetFiltersButton') }}
                            </small>
                          </transition>
                        </div>
                      </div>
                    </transition>
                  </div>

                  <div class="form-group mt-1">
                    <!--                <div>-->
                    <!--                  <label for="dit-export-select-cpes">{{ $t('PeriodicTasks.modalCPEsCaption') }}</label>-->
                    <!--                </div>-->
                    <div class="w-100 text-right mb-q selected-cpes-counter-in-DIT-exports-modal">
                      <small class="text-muted">
                        {{ $t('PeriodicTasks.modalSelectedWlansCaption') }}: {{ selectedWlansCount }}
                      </small>
                    </div>
<!--                    <multiselect-->
<!--                      @input="handleModalWlanMultiselectFieldInput"-->
<!--                      id="dit-export-select-cpes"-->
<!--                      :closeOnSelect="true"-->
<!--                      :hideSelected="true"-->
<!--                      :disabled="isDisable"-->
<!--                      :multiple="true"-->
<!--                      v-model="wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector"-->
<!--                      :options="filterdWlansListForModal || []"-->
<!--                      label="ssid"-->
<!--                      trackBy="id"-->
<!--                      :selectedLabel="$t('general.multiSelectSelected')"-->
<!--                      selectLabel="+"-->
<!--                      deselectLabel="-"-->
<!--                      :placeholder="$t('PeriodicTasks.modalWlanListPlaceholder')"-->
<!--                      class="input multiselect-max-height-in-periodic-tasks-modal"-->
<!--                      :class="{ 'is-danger': isWlansLengthErrorShowing === true }"-->
<!--                    >-->
<!--                      &lt;!&ndash;                  <template slot="option" slot-scope="props">&ndash;&gt;-->
<!--                      &lt;!&ndash;                  <div class="option__desc" v-if="!showDescriptionInCpesFilter">&ndash;&gt;-->
<!--                      &lt;!&ndash;                    <span&ndash;&gt;-->
<!--                      &lt;!&ndash;                      class="badge-cpe badge-status ml-h tooltip-text"&ndash;&gt;-->
<!--                      &lt;!&ndash;                      :class="{&ndash;&gt;-->
<!--                      &lt;!&ndash;                        'badge-status__connected': props.option.connected,&ndash;&gt;-->
<!--                      &lt;!&ndash;                        'badge-status__disconnected': !props.option.connected&ndash;&gt;-->
<!--                      &lt;!&ndash;                      }"&ndash;&gt;-->
<!--                      &lt;!&ndash;                      v-tooltip.top-center="{&ndash;&gt;-->
<!--                      &lt;!&ndash;                        content: `<ul class='list-unstyled text-left m-0'>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                          <li><small><b>id</b>: ${props.option.id}</small></li>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                        </ul>`&ndash;&gt;-->
<!--                      &lt;!&ndash;                      }"&ndash;&gt;-->
<!--                      &lt;!&ndash;                    >&ndash;&gt;-->
<!--                      &lt;!&ndash;                      <span v-if="props.option.name">{{ props.option.name }}</span>&ndash;&gt;-->
<!--                      &lt;!&ndash;                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>&ndash;&gt;-->
<!--                      &lt;!&ndash;                    </span>&ndash;&gt;-->
<!--                      &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                      &lt;!&ndash;                  <div class="option__desc" v-if="showDescriptionInCpesFilter">&ndash;&gt;-->
<!--                      &lt;!&ndash;                    <span&ndash;&gt;-->
<!--                      &lt;!&ndash;                      class="badge-cpe badge-status ml-h tooltip-text"&ndash;&gt;-->
<!--                      &lt;!&ndash;                      :class="{&ndash;&gt;-->
<!--                      &lt;!&ndash;                        'badge-status__connected': props.option.connected,&ndash;&gt;-->
<!--                      &lt;!&ndash;                        'badge-status__disconnected': !props.option.connected&ndash;&gt;-->
<!--                      &lt;!&ndash;                      }"&ndash;&gt;-->
<!--                      &lt;!&ndash;                      v-tooltip.top-center="{&ndash;&gt;-->
<!--                      &lt;!&ndash;                        content: `<ul class='list-unstyled text-left m-0'>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                          <li><small><b>id</b>: ${props.option.id}</small></li>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                          <li><small>${$t('general.description')}: <b></b> ${props.option.description ? props.option.description : $t('general.no').toLowerCase()}</small></li>&ndash;&gt;-->
<!--                      &lt;!&ndash;                                        </ul>`&ndash;&gt;-->
<!--                      &lt;!&ndash;                      }"&ndash;&gt;-->
<!--                      &lt;!&ndash;                    >&ndash;&gt;-->
<!--                      &lt;!&ndash;                      <template v-if="props.option.name">&ndash;&gt;-->
<!--                      &lt;!&ndash;                        <span v-if="!props.option.description">{{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}</span>&ndash;&gt;-->
<!--                      &lt;!&ndash;                        <span v-if="props.option.description">{{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0]}}: {{ cutOffLongTextForCPEDescription(props.option.description)}}</span>&ndash;&gt;-->
<!--                      &lt;!&ndash;                      </template>&ndash;&gt;-->
<!--                      &lt;!&ndash;                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>&ndash;&gt;-->
<!--                      &lt;!&ndash;                    </span>&ndash;&gt;-->
<!--                      &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                      &lt;!&ndash;                  </template>&ndash;&gt;-->
<!--                      <span slot="noResult">-->
<!--                        <small v-if="isSelectedNoDefaultsValuesInFiltersForWlanListANDFiltringEnabled">-->
<!--                          {{ $t('PeriodicTasks.modalWlanListNoResultMsgWhenFilterEnabled') }}-->
<!--                        </small>-->
<!--                        <small v-else>{{ $t('PeriodicTasks.modalWlanListNoResultMsg') }}</small>-->
<!--                      </span>-->
<!--                      <span slot="noOptions">-->
<!--                        <small v-if="isSelectedNoDefaultsValuesInFiltersForWlanListANDFiltringEnabled">-->
<!--                          {{ $t('PeriodicTasks.modalWlanListNoOptionsMsgWhenFilterEnabled') }}-->
<!--                        </small>-->
<!--                        <small v-else>{{ $t('PeriodicTasks.modalWlanListNoOptionsMsg') }}</small>-->
<!--                      </span>-->
<!--                    </multiselect>-->
                    <multiselect
                      @input="handleModalWlanMultiselectFieldInput"
                      :loading="loadingDataForWlanListForMultiedit"
                      id="dit-export-select-cpes"
                      :closeOnSelect="true"
                      :hideSelected="true"
                      :disabled="isDisable"
                      :multiple="true"
                      v-model="wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector"
                      :options="wlanListForWlanSearchForMultiedit || []"
                      label="ssid"
                      trackBy="id"
                      :selectedLabel="$t('general.multiSelectSelected')"
                      selectLabel="+"
                      deselectLabel="-"
                      :placeholder="$t('PeriodicTasks.modalWlanListPlaceholder')"
                      class="input multiselect-max-height-in-periodic-tasks-modal"
                      :class="{ 'is-danger': isWlansLengthErrorShowing === true }"
                      @search-change="asyncSearchWlanWhenSearchChangeForMultiedit"
                      @open="asyncSearchWlanWhenOpenSelectorListForMultiedit"
                    >
                      <!--                  <template slot="option" slot-scope="props">-->
                      <!--                  <div class="option__desc" v-if="!showDescriptionInCpesFilter">-->
                      <!--                    <span-->
                      <!--                      class="badge-cpe badge-status ml-h tooltip-text"-->
                      <!--                      :class="{-->
                      <!--                        'badge-status__connected': props.option.connected,-->
                      <!--                        'badge-status__disconnected': !props.option.connected-->
                      <!--                      }"-->
                      <!--                      v-tooltip.top-center="{-->
                      <!--                        content: `<ul class='list-unstyled text-left m-0'>-->
                      <!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
                      <!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
                      <!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
                      <!--                                        </ul>`-->
                      <!--                      }"-->
                      <!--                    >-->
                      <!--                      <span v-if="props.option.name">{{ props.option.name }}</span>-->
                      <!--                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
                      <!--                    </span>-->
                      <!--                  </div>-->
                      <!--                  <div class="option__desc" v-if="showDescriptionInCpesFilter">-->
                      <!--                    <span-->
                      <!--                      class="badge-cpe badge-status ml-h tooltip-text"-->
                      <!--                      :class="{-->
                      <!--                        'badge-status__connected': props.option.connected,-->
                      <!--                        'badge-status__disconnected': !props.option.connected-->
                      <!--                      }"-->
                      <!--                      v-tooltip.top-center="{-->
                      <!--                        content: `<ul class='list-unstyled text-left m-0'>-->
                      <!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
                      <!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
                      <!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
                      <!--                                          <li><small>${$t('general.description')}: <b></b> ${props.option.description ? props.option.description : $t('general.no').toLowerCase()}</small></li>-->
                      <!--                                        </ul>`-->
                      <!--                      }"-->
                      <!--                    >-->
                      <!--                      <template v-if="props.option.name">-->
                      <!--                        <span v-if="!props.option.description">{{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}</span>-->
                      <!--                        <span v-if="props.option.description">{{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0]}}: {{ cutOffLongTextForCPEDescription(props.option.description)}}</span>-->
                      <!--                      </template>-->
                      <!--                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
                      <!--                    </span>-->
                      <!--                  </div>-->
                      <!--                  </template>-->
                      <span slot="noResult">
                        <small v-if="isSelectedNoDefaultsValuesInFiltersForWlanListANDFiltringEnabled">
                          {{ $t('PeriodicTasks.modalWlanListNoResultMsgWhenFilterEnabled') }}
                        </small>
                        <small v-else>{{ $t('PeriodicTasks.modalWlanListNoResultMsg') }}</small>
                      </span>
                      <span slot="noOptions">
                        <small v-if="isSelectedNoDefaultsValuesInFiltersForWlanListANDFiltringEnabled">
                          {{ $t('PeriodicTasks.modalWlanListNoOptionsMsgWhenFilterEnabled') }}
                        </small>
                        <small v-else>{{ $t('PeriodicTasks.modalWlanListNoOptionsMsg') }}</small>
                      </span>
                    </multiselect>
                    <div class="w-100 d-flex justify-content-between">
                      <div>
                        <span v-show="isWlansLengthErrorShowing === true" class="help is-danger">
                          {{ $t('PeriodicTasks.modalSelectedWlansLengthError') }}
                        </span>
                      </div>
                      <!--                  <div style="text-align: right">-->
                      <!--                    <a href="#" class="ml-q cpes-show-description"-->
                      <!--                       @click.prevent="toggleShowingDescriptionInCpesFilter">-->
                      <!--                      {{ showDescriptionInCpesFilter ? $t('marketing.hideDescription') :-->
                      <!--                      $t('marketing.showDescription') }}-->
                      <!--                    </a>-->
                      <!--                  </div>-->
                    </div>
                  </div>
                </div>

                <div v-if="isRebootPointActionTypeSelected" :key="'isRebootPointActionTypeSelectedOne'">
                  <div class="filter mb-1 cpe-select-block">
                    <div>
                      <label for="dit-export-select-cpes">{{ $t('PeriodicTasks.modalCPEsCaption') }}</label>
                    </div>
<!--                    <Loader-spinner-small centered v-if="isCpesListFiltering"></Loader-spinner-small>-->
                    <div>
                      <div
                        :class="{
                          'showing-filters-for-cpe-list-block-button': !filtersForCpeList.isFiltersForCpeListBlockShowing
                        }"
                        @click="toggleIsFiltersForCpeListBlockShowing"
                        role="button"
                        v-if="!isDisable"
                      >
                        <transition name="fadeforcpefiltersblockinPeriodicTasksBlock">
                          <i v-if="filtersForCpeList.isFiltersForCpeListBlockShowing" class="icon-arrow-down"></i>
                          <i v-else class="icon-arrow-right"></i>
                        </transition>
                        {{ $t('PeriodicTasks.filtersForCpeListSwitcherCaption') }}
                        &nbsp;
                        <transition name="fadeforcpefiltersblockinPeriodicTasksBlock">
                          <span
                            class="badge badge-light badge-success"
                            v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"
                          >
                            {{ $t('PeriodicTasks.filtersForCpeListFiltringForCpeListIsEnabled') }} &nbsp;
                          </span>
                        </transition>
                        &nbsp;
                        <info :content="$t('PeriodicTasks.filtersForCpeListSwitcherInfoTooltip')" />
                      </div>
                    </div>
<!--                    <transition name="fadeforcpefiltersblockinPeriodicTasksBlock">-->
<!--                      <div-->
<!--                        v-if="filtersForCpeList.isFiltersForCpeListBlockShowing === true && !isDisable"-->
<!--                        class="filters-for-cpe-list ml-2 mt-1"-->
<!--                      >-->
<!--                        <div class="row">-->
<!--                          <div class="col-lg-12">-->
<!--                            <div class="form-group mb-q">-->
<!--                              <label for="locationForCpeFilter">-->
<!--                                {{ $t('PeriodicTasks.filtersForCpeListSwitcherLocationCaption') }}-->
<!--                              </label>-->
<!--                              <select-->
<!--                                v-model="filtersForCpeList.location"-->
<!--                                name="locationForCpeFilter"-->
<!--                                id="locationForCpeFilter"-->
<!--                                :class="{ 'select-disabled': !filtersForCpeList.isFiltersForCpeListEnabled }"-->
<!--                                class="form-control"-->
<!--                                :disabled="!filtersForCpeList.isFiltersForCpeListEnabled"-->
<!--                              >-->
<!--                                <option :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                                <option v-for="location in locationsList" :key="location.id" :value="location.id">-->
<!--                                  {{ location.name }}-->
<!--                                </option>-->
<!--                              </select>-->
<!--                            </div>-->
<!--                          </div>-->

<!--                          <div class="col-lg-6"></div>-->
<!--                        </div>-->
<!--                        <div class="row">-->
<!--                          <div class="col-lg-6">-->
<!--                            <div class="form-group mt-q d-flex justify-content-between">-->
<!--                              <button-->
<!--                                :disabled="filterdCpesListForModal.length === 0"-->
<!--                                @click="addAllFilteredCpesToSelected"-->
<!--                                style="font-weight: bold; white-space: normal; display: inline-flex;"-->
<!--                                type="button"-->
<!--                                class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"-->
<!--                              >-->
<!--                                <span style="font-size: 0.8em;">-->
<!--                                  {{ $t('PeriodicTasks.selectAllCpesButtonCaption') }}-->
<!--                                  <span>({{ this.filterdCpesListForModal.length }})</span>-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                          <div class="col-lg-6">-->
<!--                            <div class="form-group mt-q d-flex justify-content-between">-->
<!--                              <button-->
<!--                                @click.stop="clearSelectedCpesList"-->
<!--                                style="font-weight: bold; white-space: normal; display: inline-flex;"-->
<!--                                type="button"-->
<!--                                class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"-->
<!--                              >-->
<!--                                <span class="fa fa-close"></span>-->
<!--                                <span style="font-size: 0.8em;">-->
<!--                                  {{ $t('PeriodicTasks.cancelSelectAllCpesButtonCaption') }}-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="mt-0 mb-1">-->
<!--                          <transition name="fadeforcpefiltersblockinPeriodicTasksBlock" mode="out-in">-->
<!--                            <small-->
<!--                              v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"-->
<!--                              :class="{ 'item-for-delete': !isDisable }"-->
<!--                              @click="clearFiltersForCpeList"-->
<!--                              class=""-->
<!--                            >-->
<!--                              <i v-if="!isDisable" class="fa fa-remove text-danger"></i>-->
<!--                              {{ $t('PeriodicTasks.filtersForCpeListResetFiltersButton') }}-->
<!--                            </small>-->
<!--                          </transition>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </transition>-->


                                        <transition name="fadeforcpefiltersblockinPeriodicTasksBlock">
                      <div
                        v-if="filtersForCpeList.isFiltersForCpeListBlockShowing === true && !isDisable"
                        class="filters-for-cpe-list ml-2 mt-1"
                        style="position: relative"
                      >
                        <Loader-spinner-small centered v-if="showSpinnerOverCPEsFilterInModal"></Loader-spinner-small>
                        <div class="row">

                          <div class="col-lg-12">
                            <div class="form-group mb-q">
                              <label for="locationForCpeFilter">
                                {{ $t('PeriodicTasks.filtersForCpeListSwitcherLocationCaption') }}
                              </label>
                              <select
                                @change="selectLocationInCpesFilterForModal"
                                v-model="filtersForCpeList.location"
                                name="locationForCpeFilter"
                                id="locationForCpeFilter"
                                :class="{ 'select-disabled': !filtersForCpeList.isFiltersForCpeListEnabled }"
                                class="form-control"
                                :disabled="!filtersForCpeList.isFiltersForCpeListEnabled"
                              >
                                <option :value="'All locations'">{{ $t('general.allLocations') }}</option>
                                <option v-for="location in locationsList" :key="location.id" :value="location.id">
                                  {{ location.name }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-6"></div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group mt-q d-flex justify-content-between">
                              <button
                                :disabled="cpeListLengthForSelectedInModalLocation === 0 || cpeListLengthForSelectedInModalLocation === undefined"
                                @click="addAllFilteredCpesToSelected"
                                style="font-weight: bold; white-space: normal; display: inline-flex;"
                                type="button"
                                class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"
                              >
                                <span style="font-size: 0.8em;">
                                  {{ $t('PeriodicTasks.selectAllCpesButtonCaption') }}
                                  <span>({{ cpeListLengthForSelectedInModalLocation }})</span>
                                </span>
                              </button>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group mt-q d-flex justify-content-between">
                              <button
                                @click.stop="clearSelectedCpesList"
                                style="font-weight: bold; white-space: normal; display: inline-flex;"
                                type="button"
                                class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"
                              >
                                <span class="fa fa-close"></span>
                                <span style="font-size: 0.8em;">
                                  {{ $t('PeriodicTasks.cancelSelectAllCpesButtonCaption') }}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="mt-0 mb-1">
                          <transition name="fadeforcpefiltersblockinPeriodicTasksBlock" mode="out-in">
                            <small
                              v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"
                              :class="{ 'item-for-delete': !isDisable }"
                              @click="clearFiltersForCpeList"
                              class=""
                            >
                              <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                              {{ $t('PeriodicTasks.filtersForCpeListResetFiltersButton') }}
                            </small>
                          </transition>
                        </div>
                      </div>
                    </transition>


                  </div>

                  <div class="form-group mt-1">
                    <!--                <div>-->
                    <!--                  <label for="dit-export-select-cpes">{{ $t('PeriodicTasks.modalCPEsCaption') }}</label>-->
                    <!--                </div>-->
                    <div class="w-100 text-right mb-q selected-cpes-counter-in-DIT-exports-modal">
                      <small class="text-muted">
                        {{ $t('PeriodicTasks.modalSelectedCPEsCaption') }}: {{ selectedCpesCount }}
                      </small>
                    </div>
<!--                    <multiselect-->
<!--                      @input="handleModalCpesMultiselectFieldInput"-->
<!--                      id="dit-export-select-cpes"-->
<!--                      :closeOnSelect="true"-->
<!--                      :hideSelected="true"-->
<!--                      :disabled="isDisable"-->
<!--                      :multiple="true"-->
<!--                      v-model="cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector"-->
<!--                      :options="filterdCpesListForModal || []"-->
<!--                      label="name"-->
<!--                      trackBy="id"-->
<!--                      :selectedLabel="$t('general.multiSelectSelected')"-->
<!--                      selectLabel="+"-->
<!--                      deselectLabel="-"-->
<!--                      :placeholder="$t('PeriodicTasks.modalCpeListPlaceholder')"-->
<!--                      class="input multiselect-max-height-in-periodic-tasks-modal"-->
<!--                      :class="{ 'is-danger': isCpesLengthErrorShowing === true }"-->
<!--                    >-->
<!--                      <template slot="option" slot-scope="props">-->
<!--                        <div class="option__desc" v-if="!showDescriptionInCpesFilter">-->
<!--                          <span-->
<!--                            class="badge-cpe badge-status ml-h tooltip-text"-->
<!--                            :class="{-->
<!--                              'badge-status__connected': props.option.connected,-->
<!--                              'badge-status__disconnected': !props.option.connected-->
<!--                            }"-->
<!--                            v-tooltip.top-center="{-->
<!--                              content: `<ul class='list-unstyled text-left m-0'>-->
<!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
<!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
<!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
<!--                                        </ul>`-->
<!--                          ,-->
<!--                          popperOptions: {-->
<!--                           modifiers: {-->
<!--                            preventOverflow: {-->
<!--                             boundariesElement: 'window'-->
<!--                            }-->
<!--                           }-->
<!--                          }-->
<!--                          }"-->
<!--                          >-->
<!--                            <span v-if="props.option.name">{{ props.option.name }}</span>-->
<!--                            <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="option__desc" v-if="showDescriptionInCpesFilter">-->
<!--                          <span-->
<!--                            class="badge-cpe badge-status ml-h tooltip-text"-->
<!--                            :class="{-->
<!--                              'badge-status__connected': props.option.connected,-->
<!--                              'badge-status__disconnected': !props.option.connected-->
<!--                            }"-->
<!--                            v-tooltip.top-center="{-->
<!--                              content: `<ul class='list-unstyled text-left m-0'>-->
<!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
<!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
<!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
<!--                                          <li><small>${$t('general.description')}: <b></b> ${-->
<!--                                props.option.description ? props.option.description : $t('general.no').toLowerCase()-->
<!--                              }</small></li>-->
<!--                                        </ul>`-->
<!--                          ,-->
<!--                          popperOptions: {-->
<!--                           modifiers: {-->
<!--                            preventOverflow: {-->
<!--                             boundariesElement: 'window'-->
<!--                            }-->
<!--                           }-->
<!--                          }-->

<!--                          }"-->
<!--                          >-->
<!--                            <template v-if="props.option.name">-->
<!--                              <span v-if="!props.option.description">-->
<!--                                {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}-->
<!--                              </span>-->
<!--                              <span v-if="props.option.description">-->
<!--                                {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:-->
<!--                                {{ cutOffLongTextForCPEDescription(props.option.description) }}-->
<!--                              </span>-->
<!--                            </template>-->
<!--                            <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                      </template>-->
<!--                      <span slot="noResult">-->
<!--                        <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">-->
<!--                          {{ $t('PeriodicTasks.modalCpeListNoResultMsgWhenFilterEnabled') }}-->
<!--                        </small>-->
<!--                        <small v-else>{{ $t('PeriodicTasks.modalCpeListNoResultMsg') }}</small>-->
<!--                      </span>-->
<!--                      <span slot="noOptions">-->
<!--                        <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">-->
<!--                          {{ $t('PeriodicTasks.modalCpeListNoOptionsMsgWhenFilterEnabled') }}-->
<!--                        </small>-->
<!--                        <small v-else>{{ $t('PeriodicTasks.modalCpeListNoOptionsMsg') }}</small>-->
<!--                      </span>-->
<!--                    </multiselect>-->

                    <multiselect
                      @input="handleModalCpesMultiselectFieldInput"
                       :loading="loadingDataForCpeListForMultiedit"
                      id="dit-export-select-cpes"
                      :closeOnSelect="true"
                      :hideSelected="true"
                      :disabled="isDisable"
                      :multiple="true"
                      v-model="cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector"
                      :options="cpeListForCpeSearchForMultiedit || []"
                      label="name"
                      trackBy="id"
                      :selectedLabel="$t('general.multiSelectSelected')"
                      selectLabel="+"
                      deselectLabel="-"
                      :placeholder="$t('PeriodicTasks.modalCpeListPlaceholder')"
                      class="input multiselect-max-height-in-periodic-tasks-modal"
                      :class="{ 'is-danger': isCpesLengthErrorShowing === true }"
                      @search-change="asyncSearchCpeWhenSearchChangeForMultiedit"
                      @open="asyncSearchCpeWhenOpenSelectorListForMultiedit"
                    >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc" v-if="!showDescriptionInCpesFilter">
                          <span
                            class="badge-cpe badge-status ml-h tooltip-text"
                            :class="{
                              'badge-status__connected': props.option.connected,
                              'badge-status__disconnected': !props.option.connected
                            }"
                            v-tooltip.top-center="{
                              content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                        </ul>`
                          ,
                          popperOptions: {
                           modifiers: {
                            preventOverflow: {
                             boundariesElement: 'window'
                            }
                           }
                          }
                          }"
                          >
                            <span v-if="props.option.name">{{ props.option.name }}</span>
                            <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                          </span>
                        </div>
                        <div class="option__desc" v-if="showDescriptionInCpesFilter">
                          <span
                            class="badge-cpe badge-status ml-h tooltip-text"
                            :class="{
                              'badge-status__connected': props.option.connected,
                              'badge-status__disconnected': !props.option.connected
                            }"
                            v-tooltip.top-center="{
                              content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                          <li><small>${$t('general.description')}: <b></b> ${
                                props.option.description ? props.option.description : $t('general.no').toLowerCase()
                              }</small></li>
                                        </ul>`
                          ,
                          popperOptions: {
                           modifiers: {
                            preventOverflow: {
                             boundariesElement: 'window'
                            }
                           }
                          }

                          }"
                          >
                            <template v-if="props.option.name">
                              <span v-if="!props.option.description">
                                {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}
                              </span>
                              <span v-if="props.option.description">
                                {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:
                                {{ cutOffLongTextForCPEDescription(props.option.description) }}
                              </span>
                            </template>
                            <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                          </span>
                        </div>
                      </template>
                      <span slot="noResult">
                        <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">
                          {{ $t('PeriodicTasks.modalCpeListNoResultMsgWhenFilterEnabled') }}
                        </small>
                        <small v-else>{{ $t('PeriodicTasks.modalCpeListNoResultMsg') }}</small>
                      </span>
                      <span slot="noOptions">
                        <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">
                          {{ $t('PeriodicTasks.modalCpeListNoOptionsMsgWhenFilterEnabled') }}
                        </small>
                        <small v-else>{{ $t('PeriodicTasks.modalCpeListNoOptionsMsg') }}</small>
                      </span>
                    </multiselect>
                    <div class="w-100 d-flex justify-content-between">
                      <div>
                        <span v-show="isCpesLengthErrorShowing === true" class="help is-danger">
                          {{ $t('PeriodicTasks.modalSelectedCpesLengthError') }}
                        </span>
                      </div>
                      <div style="text-align: right;">
                        <a
                          href="#"
                          class="ml-q cpes-show-description"
                          @click.prevent="toggleShowingDescriptionInCpesFilter"
                        >
                          {{
                            showDescriptionInCpesFilter
                              ? $t('marketing.hideDescription')
                              : $t('marketing.showDescription')
                          }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>

              <!--            <div class="form-group action-type-block-in-periodic-task-modal ">-->
              <!--              <label for="actionType">{{ $t('PeriodicTasks.modalActionTypeCaption') }}</label>-->
              <!--              <select id="actionType" name="actionType" v-model="updatedOrNewPeriodicTask.action" class="form-control">-->
              <!--                <option-->
              <!--                  :disabled="isDisable"-->
              <!--                  v-for="type of PERIODIC_TASK_ACTION_TYPES"-->
              <!--                  :value="type"-->
              <!--                  :key="type"-->
              <!--                  :selected="updatedOrNewPeriodicTask.action === type"-->
              <!--                >-->
              <!--                  {{ $t(`PeriodicTasks.actionType.${type}`) }}-->
              <!--                </option>-->
              <!--              </select>-->
              <!--            </div>-->

              <div class="form-group mt-1 spec-type-block-in-periodic-task-modal">
                <label for="periodic-task-spec-type">{{ $t('PeriodicTasks.modalSpecCaption') }}</label>
                <select
                  @change="selectSpecTypeHandler"
                  id="periodic-task-spec-type"
                  name="periodic-task-spec-type"
                  v-model="specTypeForModal"
                  class="form-control"
                >
                  <option
                    :disabled="isDisable"
                    v-for="type of PERIODIC_TASK_SPEC_FIELD_TYPES"
                    :value="type"
                    :key="type"
                    :selected="updatedOrNewPeriodicTask.notify === type"
                  >
                    {{ $t(`PeriodicTasks.specFieldCaption${type}`) }} ({{
                      $t(`PeriodicTasks.specFieldInfo${type}`).toLowerCase()
                    }})
                  </option>
                </select>
              </div>

              <transition name="periodicTasksModalTransition" mode="out-in">
                <div key="alert-with-msg-for-spec-every" v-if="specTypeForModal === '@every'" class="mb-1">
                  <div class="d-flex flex-column justify-content-between">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-column field-wrapper-for-spec-every-input">
                        <!--                      days-->
                        <div class="text-left">
                          <span>{{ $t('PeriodicTasks.days') }}</span>
                        </div>
                        <div>
                          <input
                            :disabled="isDisable"
                            @input="specEveryTypeDaysInputHandler"
                            v-validate="'required|integer|min_value:0'"
                            :data-vv-as="$t('PeriodicTasks.days')"
                            type="number"
                            name="specEveryTypeDaysInput"
                            id="specEveryTypeDaysInput"
                            class="form-control field-for-spec-type-every-fields"
                            :class="{ input: true, 'is-danger': errors.has('specEveryTypeDaysInput') }"
                            v-model.number="specEveryDurationObjectForModal.days"
                            data-vv-validate-on="none"
                          />
                          <span v-show="errors.has('specEveryTypeDaysInput')" class="help is-danger">
                            {{ errors.first('specEveryTypeDaysInput') }}
                          </span>
                        </div>
                      </div>

                      <div class="d-flex flex-column field-wrapper-for-spec-every-input">
                        <!--                      hours-->
                        <div class="text-left">
                          <span>{{ $t('PeriodicTasks.hours') }}</span>
                        </div>
                        <div>
                          <input
                            :disabled="isDisable"
                            @input="specEveryTypeHoursInputHandler"
                            v-validate="'required|integer|min_value:0'"
                            :data-vv-as="$t('PeriodicTasks.hours')"
                            type="number"
                            name="specEveryTypeHoursInput"
                            id="specEveryTypeHoursInput"
                            class="form-control field-for-spec-type-every-fields"
                            :class="{ input: true, 'is-danger': errors.has('specEveryTypeHoursInput') }"
                            v-model.number="specEveryDurationObjectForModal.hours"
                            data-vv-validate-on="none"
                          />
                          <span v-show="errors.has('specEveryTypeHoursInput')" class="help is-danger">
                            {{ errors.first('specEveryTypeHoursInput') }}
                          </span>
                        </div>
                      </div>

                      <div class="d-flex flex-column field-wrapper-for-spec-every-input">
                        <!--                      minutes-->
                        <div class="text-left">
                          <span>{{ $t('PeriodicTasks.minutes') }}</span>
                        </div>
                        <div>
                          <input
                            :disabled="isDisable"
                            @input="specEveryTypeMinutesInputHandler"
                            v-validate="'required|integer|min_value:0'"
                            :data-vv-as="$t('PeriodicTasks.minutes')"
                            type="number"
                            name="specEveryTypeMinutesInput"
                            id="specEveryTypeMinutesInput"
                            class="form-control field-for-spec-type-every-fields"
                            :class="{ input: true, 'is-danger': errors.has('specEveryTypeMinutesInput') }"
                            v-model.number="specEveryDurationObjectForModal.minutes"
                            data-vv-validate-on="none"
                          />
                          <span v-show="errors.has('specEveryTypeMinutesInput')" class="help is-danger">
                            {{ errors.first('specEveryTypeMinutesInput') }}
                          </span>
                        </div>
                      </div>

                      <div class="d-flex flex-column field-wrapper-for-spec-every-input">
                        <!--                      seconds-->
                        <div class="text-left">
                          <span>{{ $t('PeriodicTasks.seconds') }}</span>
                        </div>
                        <div>
                          <input
                            :disabled="isDisable"
                            @input="specEveryTypeSecondsInputHandler"
                            v-validate="'required|integer|min_value:0'"
                            :data-vv-as="$t('PeriodicTasks.seconds')"
                            type="number"
                            name="specEveryTypeSecondsInput"
                            id="specEveryTypeSecondsInput"
                            class="form-control field-for-spec-type-every-fields"
                            :class="{ input: true, 'is-danger': errors.has('specEveryTypeSecondsInput') }"
                            v-model.number="specEveryDurationObjectForModal.seconds"
                            data-vv-validate-on="none"
                          />
                          <span v-show="errors.has('specEveryTypeSecondsInput')" class="help is-danger">
                            {{ errors.first('specEveryTypeSecondsInput') }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <transition name="periodicTasksModalTransition" mode="out-in">
                      <div v-if="isAllZerosInSpecEveryDurationObjectErrorShowing === true">
                        <div class="text-center">
                          <span
                            v-show="isAllZerosInSpecEveryDurationObjectErrorShowing === true"
                            class="help is-danger"
                          >
                            {{ $t('PeriodicTasks.allZerosInSpecEveryDurationObjectError') }}
                          </span>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </transition>

              <transition name="periodicTasksModalTransition" mode="out-in">
                <div key="alert-with-msg-for-spec-raw" v-if="specTypeForModal === 'raw'">
                  <Alert class="alert-info">
                    <template #text>
                      <span class="mr-h">
                        {{ $t('PeriodicTasks.modalSpecRawInfoMsg') }}:
                        <a target="_blank" href="https://pkg.go.dev/github.com/robfig/cron">
                          https://pkg.go.dev/github.com/robfig/cron
                        </a>
                      </span>
                    </template>
                  </Alert>
                </div>
              </transition>
              <transition name="periodicTasksModalTransition" mode="out-in">
                <div class="form-group" key="input-for-spec-raw" v-if="specTypeForModal === 'raw'">
                  <label for="periodic-task-spec-raw">{{ $t('PeriodicTasks.modalSpecRawCaption') }}</label>
                  <input
                    type="text"
                    :data-vv-as="$t('PeriodicTasks.modalSpecRawCaption')"
                    id="periodic-task-spec-raw"
                    :placeholder="$t('PeriodicTasks.modalSpecRawPlaceholder')"
                    name="periodic-task-spec-raw"
                    :disabled="isDisable"
                    v-validate="'required|max:50'"
                    class="form-control"
                    :class="{ input: true, 'is-danger': errors.has('periodic-task-spec-raw') }"
                    v-model="updatedOrNewPeriodicTask.spec"
                    @input="handleModalSpecFieldRawInput"
                    data-vv-validate-on="none"
                  />
                  <span v-show="errors.has('periodic-task-spec-raw')" class="help is-danger">
                    {{ errors.first('periodic-task-spec-raw') }}
                  </span>
                </div>
              </transition>

              <!--              <h5>значение в поле spec, улетит на бэк: {{updatedOrNewPeriodicTask.spec}}</h5>-->

              <div class="form-group mt-1">
                <label for="notifyType">{{ $t('PeriodicTasks.modalNotifyTypeCaption') }}</label>
                <select
                  @change="selectNotifyTypeHandler"
                  id="notifyType"
                  name="notifyType"
                  v-model="updatedOrNewPeriodicTask.notify"
                  class="form-control"
                >
                  <option
                    :disabled="isDisable"
                    v-for="type of PERIODIC_TASK_NOTIFICATION_TYPES"
                    :value="type"
                    :key="type"
                    :selected="updatedOrNewPeriodicTask.notify === type"
                  >
                    {{ $t(`PeriodicTasks.notifyType.${type}`) }}
                  </option>
                </select>
              </div>

              <transition name="periodicTasksModalTransition" mode="out-in">
                <div :key="'telegram'" v-if="updatedOrNewPeriodicTask.notify === 'telegram'" class="form-group mt-1">
                  <div class="form-group mt-1">
                    <label for="notifySettingsTelegramToken">
                      {{ $t('PeriodicTasks.notifySettingsTelegramTokenCaption') }}
                      <info class="ml-q" :content="$t('PeriodicTasks.notifySettingsTelegramTokenInfo')" />
                    </label>
                    <input
                      :disabled="isDisable"
                      @input="notifySettingsTelegramTokenInputHandler"
                      v-validate="'required|max:100'"
                      :data-vv-as="$t('PeriodicTasks.notifySettingsTelegramTokenCaption')"
                      type="text"
                      name="notifySettingsTelegramToken"
                      id="notifySettingsTelegramToken"
                      :placeholder="$t('PeriodicTasks.notifySettingsTelegramTokenPlaceholder')"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('notifySettingsTelegramToken') }"
                      v-model="updatedOrNewPeriodicTask.notify_settings.token"
                      data-vv-validate-on="none"
                    />
                    <span v-show="errors.has('notifySettingsTelegramToken')" class="help is-danger">
                      {{ errors.first('notifySettingsTelegramToken') }}
                    </span>
                  </div>
                  <div class="form-group mt-1">
                    <label for="notifySettingsTelegramChatId">
                      {{ $t('PeriodicTasks.notifySettingsTelegramChatIdCaption') }}
                      <info class="ml-q" :content="$t('PeriodicTasks.notifySettingsTelegramChatIdInfo')" />
                    </label>
                    <input
                      :disabled="isDisable"
                      @input="notifySettingsTelegramChatIdInputHandler"
                      v-validate="'required|integer'"
                      :data-vv-as="$t('PeriodicTasks.notifySettingsTelegramChatIdCaption')"
                      type="number"
                      name="notifySettingsTelegramChatId"
                      id="notifySettingsTelegramChatId"
                      :placeholder="$t('PeriodicTasks.notifySettingsTelegramChatIdPlaceholder')"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('notifySettingsTelegramChatId') }"
                      v-model.number="updatedOrNewPeriodicTask.notify_settings.chat_id"
                      data-vv-validate-on="none"
                    />
                    <span v-show="errors.has('notifySettingsTelegramChatId')" class="help is-danger">
                      {{ errors.first('notifySettingsTelegramChatId') }}
                    </span>
                  </div>
                  <div class="form-group mt-1">
                    <label for="notifySettingsTelegramText">
                      {{ $t('PeriodicTasks.notifySettingsTelegramTextCaption') }}
                    </label>
                    <textarea
                      :disabled="isDisable"
                      @input="notifySettingsTelegramTextInputHandler"
                      v-validate="'required'"
                      :data-vv-as="$t('PeriodicTasks.notifySettingsTelegramTextCaption')"
                      type="textarea"
                      name="notifySettingsTelegramText"
                      id="notifySettingsTelegramText"
                      :placeholder="$t('PeriodicTasks.notifySettingsTelegramTextPlaceholder')"
                      class="form-control textarea-in-rules-wizard"
                      :class="{ input: true, 'is-danger': errors.has('notifySettingsTelegramText') }"
                      v-model="updatedOrNewPeriodicTask.notify_settings.text"
                      data-vv-validate-on="none"
                    />
                    <span v-show="errors.has('notifySettingsTelegramText')" class="help is-danger">
                      {{ errors.first('notifySettingsTelegramText') }}
                    </span>
                  </div>
                </div>
                <!--            email-->
                <div :key="'email'" v-else-if="updatedOrNewPeriodicTask.notify === 'email'" class="form-group mt-1">
                  <div class="form-group mt-1">
                    <label for="notifySettingsEmailTo">{{ $t('PeriodicTasks.notifySettingsEmailToCaption') }}</label>
                    <input
                      :disabled="isDisable"
                      @input="notifySettingsEmailToInputHandler"
                      v-validate="'required|email'"
                      :data-vv-as="$t('PeriodicTasks.notifySettingsEmailToCaption')"
                      type="text"
                      name="notifySettingsEmailTo"
                      id="notifySettingsEmailTo"
                      :placeholder="$t('PeriodicTasks.notifySettingsEmailToPlaceholder')"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('notifySettingsEmailTo') }"
                      v-model="updatedOrNewPeriodicTask.notify_settings.to"
                      data-vv-validate-on="none"
                    />
                    <span v-show="errors.has('notifySettingsEmailTo')" class="help is-danger">
                      {{ errors.first('notifySettingsEmailTo') }}
                    </span>
                  </div>
                  <div class="form-group mt-1">
                    <label for="notifySettingsEmailSubject">
                      {{ $t('PeriodicTasks.notifySettingsEmailSubjectCaption') }}
                    </label>
                    <input
                      :disabled="isDisable"
                      @input="notifySettingsEmailSubjectInputHandler"
                      v-validate="'required|max:255'"
                      :data-vv-as="$t('PeriodicTasks.notifySettingsEmailSubjectCaption')"
                      type="text"
                      name="notifySettingsEmailSubject"
                      id="notifySettingsEmailSubject"
                      :placeholder="$t('PeriodicTasks.notifySettingsEmailSubjectPlaceholder')"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('notifySettingsEmailSubject') }"
                      v-model="updatedOrNewPeriodicTask.notify_settings.subject"
                      data-vv-validate-on="none"
                    />
                    <span v-show="errors.has('notifySettingsEmailSubject')" class="help is-danger">
                      {{ errors.first('notifySettingsEmailSubject') }}
                    </span>
                  </div>
                  <div class="form-group mt-1">
                    <label for="notifySettingsEmailText">
                      {{ $t('PeriodicTasks.notifySettingsEmailTextCaption') }}
                    </label>
                    <textarea
                      :disabled="isDisable"
                      @input="notifySettingsEmailTextInputHandler"
                      v-validate="'required'"
                      :data-vv-as="$t('PeriodicTasks.notifySettingsEmailTextCaption')"
                      type="textarea"
                      name="notifySettingsEmailText"
                      id="notifySettingsEmailText"
                      :placeholder="$t('PeriodicTasks.notifySettingsEmailTextPlaceholder')"
                      class="form-control textarea-in-rules-wizard"
                      :class="{ input: true, 'is-danger': errors.has('notifySettingsEmailText') }"
                      v-model="updatedOrNewPeriodicTask.notify_settings.text"
                      data-vv-validate-on="none"
                    />
                    <span v-show="errors.has('notifySettingsEmailText')" class="help is-danger">
                      {{ errors.first('notifySettingsEmailText') }}
                    </span>
                  </div>
                </div>

                <!--            mqtt-->
                <div :key="'mqtt'" v-else-if="updatedOrNewPeriodicTask.notify === 'mqtt'" class="form-group mt-1">
                  <div class="form-group mt-1">
                    <label for="notifySettingsMQTTTopic">
                      {{ $t('PeriodicTasks.notifySettingsMQTTTopicCaption') }}
                      <info class="ml-q" :content="$t('PeriodicTasks.notifySettingsMQTTTopicInfo')" />
                    </label>
                    <input
                      :disabled="isDisable"
                      @input="notifySettingsMQTTTopicInputHandler"
                      v-validate="'required|max:255'"
                      :data-vv-as="$t('PeriodicTasks.notifySettingsMQTTTopicCaption')"
                      type="text"
                      name="notifySettingsMQTTTopic"
                      id="notifySettingsMQTTTopic"
                      :placeholder="$t('PeriodicTasks.notifySettingsMQTTTopicPlaceholder')"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('notifySettingsMQTTTopic') }"
                      v-model="updatedOrNewPeriodicTask.notify_settings.topic"
                      data-vv-validate-on="none"
                    />
                    <span v-show="errors.has('notifySettingsMQTTTopic')" class="help is-danger">
                      {{ errors.first('notifySettingsMQTTTopic') }}
                    </span>
                  </div>
                  <div class="form-group mt-1">
                    <label for="notifySettingsMQTTText">{{ $t('PeriodicTasks.notifySettingsMQTTTextCaption') }}</label>
                    <textarea
                      @input="notifySettingsMQTTTextInputHandler"
                      v-validate="'required'"
                      :data-vv-as="$t('PeriodicTasks.notifySettingsMQTTTextCaption')"
                      type="textarea"
                      name="notifySettingsMQTTText"
                      id="notifySettingsMQTTText"
                      :placeholder="$t('PeriodicTasks.notifySettingsMQTTTextPlaceholder')"
                      class="form-control textarea-in-rules-wizard"
                      :class="{ input: true, 'is-danger': errors.has('notifySettingsMQTTText') }"
                      v-model="updatedOrNewPeriodicTask.notify_settings.text"
                      data-vv-validate-on="none"
                    />
                    <span v-show="errors.has('notifySettingsMQTTText')" class="help is-danger">
                      {{ errors.first('notifySettingsMQTTText') }}
                    </span>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer d-flex justify-content-between">
        <div>
          <button
            v-if="isCreatingNewPeriodicTaskModal === true && !isDisable"
            :disabled="isShowOverCreateOrEditMOdalSpinner || isDisable"
            type="button"
            class="btn btn-outline-success"
            @click="createPeriodicTask"
          >
            {{ $t('general.create') }}
          </button>
          <button
            v-if="isCreatingNewPeriodicTaskModal === false && !isDisable"
            :disabled="isShowOverCreateOrEditMOdalSpinner || isDisable"
            type="button"
            class="btn btn-outline-success"
            @click="updatePeriodicTask"
          >
            {{ $t('general.save') }}
          </button>
        </div>
        <div>
          <button
            v-if="isCreatingNewPeriodicTaskModal === true"
            :disabled="isShowOverCreateOrEditMOdalSpinner"
            type="button"
            class="btn btn-secondary"
            @click="closeModalForCreating"
          >
            {{ $t('general.close') }}
          </button>
          <button
            v-if="isCreatingNewPeriodicTaskModal === false && !isDisable"
            :disabled="isShowOverCreateOrEditMOdalSpinner || isDisable"
            type="button"
            class="btn btn-outline-danger mr-1"
            @click="openDeletePeriodicTaskModal"
          >
            {{ $t('general.delete') }}
          </button>
          <button
            v-if="isCreatingNewPeriodicTaskModal === false"
            :disabled="isShowOverCreateOrEditMOdalSpinner"
            type="button"
            class="btn btn-secondary"
            @click="closeModalForEdit"
          >
            {{ $t('general.close') }}
          </button>
        </div>
      </div>
    </modal>
    <modal
      title="Delete periodic task"
      v-model="isdeletingPeriodicTasktModalShowing"
      class="modal-danger"
      effect="fade/zoom"
    >
      <loader-spinner v-if="isShowOverDeleteModalSpinner" centered></loader-spinner>
      <h4 slot="modal-title" class="modal-title">{{ $t('PeriodicTasks.deletePeriodicTaskModalHeader') }}</h4>
      {{ $t('PeriodicTasks.deletePeriodicTaskModalText') }}
      <b>{{ updatedOrNewPeriodicTask.name }}</b>

      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          :disabled="isShowOverDeleteModalSpinner"
          @click="closeDeletePeriodicTaskModal"
        >
          {{ $t('general.cancel') }}
        </button>
        <button
          type="button"
          class="btn btn-outline-danger"
          :disabled="isShowOverDeleteModalSpinner"
          @click="deletePeriodicTask"
        >
          {{ $t('general.yesDelete') }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import Alert from '@/components/Universal/alert/alert.vue';
import PeriodicTasksService from '../../services/PeriodicTasksService';
import cpeService from '../../services/cpeService';
import locationService from '../../services/locationService';
import Modal from '../../components/Modal.vue';
import Info from '../../components/Universal/info-icon.vue';
import helpers from '../../helpers';
import wlanService from '../../services/wlanService';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

const PERIODIC_TASK_ACTION_TYPES = ['change_password', 'reboot_point'];
// const PERIODIC_TASK_NOTIFICATION_TYPES = ['telegram', 'email', 'mqtt'];
const PERIODIC_TASK_NOTIFICATION_TYPES = ['telegram', 'email'];
const PERIODIC_TASK_SPEC_FIELD_TYPES = ['@every', '@hourly', '@daily', '@weekly', '@monthly', '@yearly', 'raw'];

export default {
  name: 'PeriodicTasks',
  components: {
    Modal, Info, Multiselect, Alert, SwitchComponent, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      wlansListFromReqWithIDsLoading: false,
      cpesListFromReqWithIDsLoading: false,
      cpesListFromReqWithIDs: [],
      wlansListFromReqWithIDs: [],

      loadingAllWlansForLocation: false,
      wlanListLengthForSelectedInModalLocation: 0,
      loadingDataForWlanListLength: false,
      timeoutForWlanSearchForMultiedit: undefined,
      loadingDataForWlanListForMultiedit: false,
      wlanListForWlanSearchForMultiedit: [],

      loadingAllCpesForLocation: false,
      cpeListLengthForSelectedInModalLocation: 0,
      loadingDataForCpeListLength: false,
      timeoutForCpeSearchForMultiedit: undefined,
      loadingDataForCpeListForMultiedit: false,
      cpeListForCpeSearchForMultiedit: [],
      showLocationFilter: true,
      locationInFiltersData: [],
      specTypeForModal: '@monthly',
      specEveryDurationObjectForModal: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
      },
      charactersForCpeListSeparateNameFromDescription: ':::', // используем при создании списка CPE с описаниями  для мультиселекта
      showDescriptionInCpesFilter: false,
      periodicTasksList: [],
      updatedOrNewPeriodicTask: {},
      isEditOrNewPeriodicTaskModalShowing: false,
      isCreatingNewPeriodicTaskModal: false,
      isdeletingPeriodicTasktModalShowing: false,
      cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector: [],
      wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector: [],
      isCpesLengthErrorShowing: false,
      isWlansLengthErrorShowing: false,
      isAllZerosInSpecEveryDurationObjectErrorShowing: false,
      newPeriodicTask: {
        name: '',
        action: 'reboot_point',
        spec: '',
        points: [],
        notify: 'telegram',
        notify_settings: {
          token: '',
          chat_id: '',
          text: ''
        }
      },
      filtersForCpeList: {
        isFiltersForCpeListEnabled: false,
        isFiltersForCpeListBlockShowing: false,
        location: 'All locations'
      },
      filtersForWlanList: {
        isFiltersForWlanListEnabled: false,
        isFiltersForWlanListBlockShowing: false,
        location: 'All locations'
      },
      filters: {
        query: '',
        actionType: 'All',
        location: 'All locations',
        with_childs: true
      },
      sortByFilter: 'Name',
      sortMode: 'asc'
    };
  },
  methods: {
    async getCompactCpesAndWlansByIDsFromWatcher(val) {
      //console.log(val)
      const idsFromTasks = JSON.parse(JSON.stringify(val));
      if (Array.isArray(idsFromTasks) && idsFromTasks.length) {
        this.cpesListFromReqWithIDsLoading = true;
        this.wlansListFromReqWithIDsLoading = true;
        const cpesIdsForRequest = [];
        const wlansIdsForRequest = [];
        for (const task of idsFromTasks) {
          if (task.action === 'reboot_point') {
            for (const cpeId of task.ids) {
              cpesIdsForRequest.push(cpeId)
            }
          } else if (task.action === 'change_password') {
            for (const wlanId of task.ids) {
              wlansIdsForRequest.push(wlanId)
            }
          }

        }
        // отправляем в запросе неповторяющиеся id
        const cpesIdsForRequestUniq = Array.from(new Set(cpesIdsForRequest));
        const wlansIdsForRequestUniq = Array.from(new Set(wlansIdsForRequest));

        // делаем запросы только если есть какие-то ids. Без ids (с ids []) запрос возвращает все
        // объекты и долго выполняется
        if (Array.isArray(cpesIdsForRequestUniq) && cpesIdsForRequestUniq.length) {
          this.cpesListFromReqWithIDs = await cpeService.asyncGetCompactCpesWithDataRemainingByIds(this, cpesIdsForRequestUniq);
        }
        if (Array.isArray(wlansIdsForRequestUniq) && wlansIdsForRequestUniq.length) {
          this.wlansListFromReqWithIDs = await wlanService.asyncGetWlansWithDataRemainingByIdsWithoutClients(this, wlansIdsForRequestUniq);
        }

        this.cpesListFromReqWithIDsLoading = false;
        this.wlansListFromReqWithIDsLoading = false;
      }

    },

    async selectLocationInWlanFilterForModal() {
      if (this.locationIdForWlanSearchRequests) {
        this.getWlanLengthForLocation(this.locationIdForWlanSearchRequests, false)
      } else {
        this.getWlanLengthForLocation(undefined, true)
      }
    },
    async getWlanLengthForLocation(locationId, with_childs) {
      try {
        this.loadingDataForWlanListLength = true;
        const response = await wlanService.requestCompactWPAPersonalWlansWithoutClientsAndWithSearch('', locationId, with_childs);
       // console.log(response)
        this.wlanListLengthForSelectedInModalLocation = response.total;
      } catch (e) {
        console.log(e)
        this.wlanListLengthForSelectedInModalLocation = undefined;
      } finally {
        this.loadingDataForWlanListLength = false;

        // console.log(this.wlansListForWlansSearch[id]);
      }
    },
    async getAllWlansForLocation(locationId, with_childs) {
      try {
        this.loadingAllWlansForLocation = true;
        const response = await wlanService.requestCompactWPAPersonalWlansWithoutClientsAndWithSearch('', locationId, with_childs, this.wlanListLengthForSelectedInModalLocation);
        // console.log(response)
        return response.itemslist;
      } catch (e) {
        console.log(e)
        return []
      } finally {
        this.loadingAllWlansForLocation = false;

      }
    },
    async asyncSearchWlanWhenOpenSelectorListForMultiedit(id) {
      return this.asyncSearchWlanForMultiedit('', 0);
    },
    async asyncSearchWlanWhenSearchChangeForMultiedit(query) {
      return this.asyncSearchWlanForMultiedit(query);
    },
    async asyncSearchWlanForMultiedit(query, timeout = 1000) {
      if (this.timeoutForWlanSearchForMultiedit !== undefined) {
        clearTimeout(this.timeoutForWlanSearchForMultiedit)
      }
      this.loadingDataForWlanListForMultiedit = true;
      this.timeoutForWlanSearchForMultiedit = setTimeout(async () => {


        try {
          let response
          if (this.locationIdForWlanSearchRequests){
            response = await wlanService.requestCompactWPAPersonalWlansWithoutClientsAndWithSearch(query, this.locationIdForWlanSearchRequests);
          }else {
            response = await wlanService.requestCompactWPAPersonalWlansWithoutClientsAndWithSearch(query, undefined, true);
          }
          // const response = await cpeService.requestCompactCpesWithSearch(query, this.locationIdForCpeSearchRequests);
          this.wlanListForWlanSearchForMultiedit = response.itemslist;
        } catch (e) {
          console.log(e)
          this.wlanListForWlanSearchForMultiedit = [];
        } finally {
          this.loadingDataForWlanListForMultiedit = false;

          // console.log(this.wlansListForWlansSearch[id]);
        }
      }, timeout);
    },


    async selectLocationInCpesFilterForModal() {
      if (this.locationIdForCpeSearchRequests) {
        this.getCpesLengthForLocation(this.locationIdForCpeSearchRequests, false)
      } else {
        this.getCpesLengthForLocation(undefined, true)
      }
    },
    async getCpesLengthForLocation(locationId, with_childs) {
      try {
        this.loadingDataForCpeListLength = true;
        const response = await cpeService.requestCompactCpesWithSearch('', locationId, with_childs);
       // console.log(response)
        this.cpeListLengthForSelectedInModalLocation = response.total;
      } catch (e) {
        console.log(e)
        this.cpeListLengthForSelectedInModalLocation = undefined;
      } finally {
        this.loadingDataForCpeListLength = false;

        // console.log(this.wlansListForWlansSearch[id]);
      }
    },
    async getAllCpesForLocation(locationId, with_childs) {
      try {
        this.loadingAllCpesForLocation = true;
        const response = await cpeService.requestCompactCpesWithSearch('', locationId, with_childs, this.cpeListLengthForSelectedInModalLocation);
        // console.log(response)
        return response.itemslist;
      } catch (e) {
        console.log(e)
        return []
      } finally {
        this.loadingAllCpesForLocation= false;

      }
    },
    async asyncSearchCpeWhenOpenSelectorListForMultiedit(id) {
      return this.asyncSearchCpeForMultiedit('', 0);
    },
    async asyncSearchCpeWhenSearchChangeForMultiedit(query) {
      return this.asyncSearchCpeForMultiedit(query);
    },
    async asyncSearchCpeForMultiedit(query, timeout = 1000) {
      if (this.timeoutForCpeSearchForMultiedit !== undefined) {
        clearTimeout(this.timeoutForCpeSearchForMultiedit)
      }
      this.loadingDataForCpeListForMultiedit = true;
      this.timeoutForCpeSearchForMultiedit = setTimeout(async () => {


        try {
          let response
          if (this.locationIdForCpeSearchRequests){
            response = await cpeService.requestCompactCpesWithSearch(query, this.locationIdForCpeSearchRequests);
          }else {
            response = await cpeService.requestCompactCpesWithSearch(query, undefined, true);
          }
          // const response = await cpeService.requestCompactCpesWithSearch(query, this.locationIdForCpeSearchRequests);
          this.cpeListForCpeSearchForMultiedit = response.itemslist;
        } catch (e) {
          console.log(e)
          this.cpeListForCpeSearchForMultiedit = [];
        } finally {
          this.loadingDataForCpeListForMultiedit = false;

          // console.log(this.wlansListForWlansSearch[id]);
        }
      }, timeout);
    },
    selectLocation() {
      this.getAllPeriodicTasks(this);
    },
    setFilter(filterName, value) {
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
      }
    },
    getAllPeriodicTasks(ctx) {
      PeriodicTasksService.getPeriodicTasksList(ctx);
    },
    getCompactCpesList() {
      cpeService.getAllCompactCpes(this);
    },
    getCompactWlansList() {
      // wlanService.getAllWlans(this);
      // wlanService.getAllWPAOrWPA2PersonalWlansCompact(this);
      wlanService.getAllWPAPersonalWlansCompact(this);
    },
    getLocations() {
      locationService.getLocations(this);
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.selectLocation()


    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    showEditOrNewPeriodicTaskModal() {
      this.isEditOrNewPeriodicTaskModalShowing = true;
    },
    hideEditOrNewPeriodicTaskModal() {
      this.isEditOrNewPeriodicTaskModalShowing = false;
    },
    returnCopyOfPeriodicTaskByID(periodicTaskId) {
      try {
        for (const item of this.periodicTasksList) {
          if (item.id === periodicTaskId) {
            return JSON.parse(JSON.stringify(item));
          }
        }
        return undefined;
      } catch (e) {
        return undefined;
      }
    },
    openModalForEdit(periodicTaskId) {
      this.showDescriptionInCpesFilter = false;
      this.clearValidatorErrors();
      this.clearFiltersForCpeList();
      this.clearFiltersForWlanList();
      if (this.isSelectedDefaultLocationForRequests) {
        this.filtersForCpeList = {
          isFiltersForCpeListEnabled: false,
          isFiltersForCpeListBlockShowing: false,
          location: this.$store.getters.getDefaultLocationForRequests(this)
        };
        this.filtersForWlanList = {
          isFiltersForWlanListEnabled: false,
          isFiltersForWlanListBlockShowing: false,
          location: this.$store.getters.getDefaultLocationForRequests(this)
        };
      }
      this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector = [];
      this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector = [];
      this.isCreatingNewPeriodicTaskModal = false;
      const periodicTask = this.returnCopyOfPeriodicTaskByID(periodicTaskId);
      if (periodicTask !== undefined) {
        this.updatedOrNewPeriodicTask = periodicTask;

        if (this.updatedOrNewPeriodicTask.action === 'reboot_point') {
          // наполняем мультиселектор точек доступа точками, производя поиска по их id из объекта периодической задачи
          const { cpesList } = this;
          if (cpesList && this.updatedOrNewPeriodicTask && this.updatedOrNewPeriodicTask.ids) {
            const cpesItems = helpers.transformArrayToObject(cpesList);
            this.updatedOrNewPeriodicTask.ids.map((cpe) => {
              if (Object.prototype.hasOwnProperty.call(cpesItems, cpe)) {
                const fullCpe = cpesItems[cpe];
                fullCpe.id = cpe;
                // console.log(fullCpe);
                this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.push(fullCpe);
              } else {
                const fullCpe = { name: cpe, id: cpe };
                fullCpe.id = cpe;
                // console.log(fullCpe);
                this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.push(fullCpe);
              }
            });
          }
        } else if (this.updatedOrNewPeriodicTask.action === 'change_password') {
          // наполняем мультиселектор wlanами, производя поиска по их id из объекта периодической задачи
          const { wlansList } = this;
          if (wlansList && this.updatedOrNewPeriodicTask && this.updatedOrNewPeriodicTask.ids) {
            const wlansItems = helpers.transformArrayToObject(wlansList);
            this.updatedOrNewPeriodicTask.ids.map((wlan) => {
              if (Object.prototype.hasOwnProperty.call(wlansItems, wlan)) {
                const fullWlan = wlansItems[wlan];
                fullWlan.id = wlan;
                // console.log(fullCpe);
                this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.push(fullWlan);
              } else {
                const fullWlan = { ssid: wlan, id: wlan };
                fullWlan.id = wlan;
                // console.log(fullCpe);
                this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.push(fullWlan);
              }
            });
          }
        }

        //  парсим поле spec чтобы правильно отобразить тип периодичности в модалке
        const parsedSpecField = this.parseSpecField(this.updatedOrNewPeriodicTask.spec);
        this.clearSpecEveryDurationObjectForModal();
        this.specTypeForModal = parsedSpecField.type;
        if (parsedSpecField.type === '@every') {
          this.specEveryDurationObjectForModal = JSON.parse(JSON.stringify(parsedSpecField.duration));
        }
        this.showEditOrNewPeriodicTaskModal();
      }
    },
    // closeModalForEdit() {
    //   this.clearSpecEveryDurationObjectForModal()
    //   this.hideEditOrNewPeriodicTaskModal();
    //   this.updatedOrNewPeriodicTask = {};
    // },
    closeModalForEdit() {
      this.clearSpecEveryDurationObjectForModal();
      this.clearValidatorErrors();
      this.clearFiltersForCpeList();
      this.clearFiltersForWlanList();
      this.hideEditOrNewPeriodicTaskModal();
      this.updatedOrNewPeriodicTask = {};
      this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector = [];
      this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector = [];
    },
    openModalForCreating() {
      this.showDescriptionInCpesFilter = false;
      this.clearValidatorErrors();
      this.clearFiltersForCpeList();
      this.clearFiltersForWlanList();
      if (this.isSelectedDefaultLocationForRequests) {
        this.filtersForCpeList = {
          isFiltersForCpeListEnabled: false,
          isFiltersForCpeListBlockShowing: false,
          location: this.$store.getters.getDefaultLocationForRequests(this)
        };
        this.filtersForWlanList = {
          isFiltersForWlanListEnabled: false,
          isFiltersForWlanListBlockShowing: false,
          location: this.$store.getters.getDefaultLocationForRequests(this)
        };
      }
      this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector = [];
      this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector = [];
      this.isCreatingNewPeriodicTaskModal = true;
      this.updatedOrNewPeriodicTask = JSON.parse(JSON.stringify(this.newPeriodicTask));
      this.clearSpecEveryDurationObjectForModal();
      this.initSpecFieldInupdatedOrNewPeriodicTaskDependingOnSelectedSpecType('@monthly');
      this.showEditOrNewPeriodicTaskModal();
    },
    closeModalForCreating() {
      this.clearSpecEveryDurationObjectForModal();
      this.clearValidatorErrors();
      this.clearFiltersForCpeList();
      this.clearFiltersForWlanList();
      this.hideEditOrNewPeriodicTaskModal();
      this.updatedOrNewPeriodicTask = {};
      this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector = [];
      this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector = [];
    },
    toggleIsFiltersForCpeListBlockShowing() {
      if (this.filtersForCpeList.isFiltersForCpeListBlockShowing) {
        // this.clearFiltersForCpeList();
        this.filtersForCpeList.isFiltersForCpeListBlockShowing = false;
      } else {
        this.filtersForCpeList.isFiltersForCpeListBlockShowing = true;
        this.filtersForCpeList.isFiltersForCpeListEnabled = true;
        // this.filtersForCpeList.radarStatus = 'Enabled';
        this.selectLocationInCpesFilterForModal();
      }
    },
    toggleIsFiltersForWlanListBlockShowing() {
      if (this.filtersForWlanList.isFiltersForWlanListBlockShowing) {
        // this.clearFiltersForCpeList();
        this.filtersForWlanList.isFiltersForWlanListBlockShowing = false;
      } else {
        this.filtersForWlanList.isFiltersForWlanListBlockShowing = true;
        this.filtersForWlanList.isFiltersForWlanListEnabled = true;
        // this.filtersForCpeList.radarStatus = 'Enabled';
        this.selectLocationInWlanFilterForModal();
      }
    },
    clearFiltersForCpeList() {
      this.filtersForCpeList = {
        isFiltersForCpeListEnabled: false,
        isFiltersForCpeListBlockShowing: false,
        location: 'All locations'
      };
    },
    clearFiltersForWlanList() {
      this.filtersForWlanList = {
        isFiltersForWlanListEnabled: false,
        isFiltersForWlanListBlockShowing: false,
        location: 'All locations'
      };
    },
    handleModalCpesMultiselectFieldInput() {
      this.hideCpesLengthError();
      try {
        if (Array.isArray(this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector)) {
          for (const cpe of this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector) {
            if (cpe.name.includes(this.charactersForCpeListSeparateNameFromDescription)) {
              cpe.name = cpe.name.split(this.charactersForCpeListSeparateNameFromDescription)[0];
            }
          }
        }
      } catch (e) {}
    },
    handleModalWlanMultiselectFieldInput() {
      this.hideWlansLengthError();
      try {
        // if (Array.isArray(this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector)) {
        //   for (const wlan of this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector) {
        //     if (wlan.name.includes(this.charactersForCpeListSeparateNameFromDescription)) {
        //       wlan.name = wlan.name.split(this.charactersForCpeListSeparateNameFromDescription)[0];
        //     }
        //   }
        // }
      } catch (e) {}
    },
    toggleShowingDescriptionInCpesFilter() {
      this.showDescriptionInCpesFilter = !this.showDescriptionInCpesFilter;
    },
    // addAllFilteredCpesToSelected() {
    //   this.hideCpesLengthError();
    //   const existingCpeIdsInExportCpes = [];
    //   for (const existingCpe of this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector) {
    //     existingCpeIdsInExportCpes.push(existingCpe.id);
    //   }
    //   for (const cpe of this.filterdCpesListForModal) {
    //     if (!existingCpeIdsInExportCpes.includes(cpe.id)) {
    //       this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.push(cpe);
    //     }
    //   }
    // },


   async addAllFilteredCpesToSelected() {
      this.hideCpesLengthError();
      const existingCpeIdsInExportCpes = [];
      for (const existingCpe of this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector) {
        existingCpeIdsInExportCpes.push(existingCpe.id);
      }

      let allCpesForLocation = []
      if (this.locationIdForCpeSearchRequests) {
        allCpesForLocation = await this.getAllCpesForLocation(this.locationIdForCpeSearchRequests, false)
      } else {
        allCpesForLocation = await this.getAllCpesForLocation(undefined, true)
      }
      // console.log(allCpesForLocation)
      for (const cpe of allCpesForLocation) {
        if (!existingCpeIdsInExportCpes.includes(cpe.id)) {
          this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.push(cpe);
        }
      }
    },


    // addAllFilteredWlansToSelected() {
    //   this.hideWlansLengthError();
    //   const existingWlanIdsInExportCpes = [];
    //   for (const existingWlan of this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector) {
    //     existingWlanIdsInExportCpes.push(existingWlan.id);
    //   }
    //   for (const wlan of this.filterdWlansListForModal) {
    //     if (!existingWlanIdsInExportCpes.includes(wlan.id)) {
    //       this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.push(wlan);
    //     }
    //   }
    // },

    async addAllFilteredWlansToSelected() {
      this.hideWlansLengthError();
      const existingWlanIdsInExportCpes = [];
      for (const existingWlan of this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector) {
        existingWlanIdsInExportCpes.push(existingWlan.id);
      }

      let allWlansForLocation = []
      if (this.locationIdForWlanSearchRequests) {
        allWlansForLocation = await this.getAllWlansForLocation(this.locationIdForWlanSearchRequests, false)
      } else {
        allWlansForLocation = await this.getAllWlansForLocation(undefined, true)
      }

      for (const wlan of allWlansForLocation) {
        if (!existingWlanIdsInExportCpes.includes(wlan.id)) {
          this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.push(wlan);
        }
      }
    },

    clearSelectedCpesList() {
      // console.log(this.exportCpes);
      // this.exportCpes = []
      while (this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.length) {
        this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.pop();
      }
    },
    clearSelectedWlansList() {
      // console.log(this.exportCpes);
      // this.exportCpes = []
      while (this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.length) {
        this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.pop();
      }
    },
    hideCpesLengthError() {
      this.isCpesLengthErrorShowing = false;
    },
    showCpesLengthError() {
      this.isCpesLengthErrorShowing = true;
    },
    showWlansLengthError() {
      this.isWlansLengthErrorShowing = true;
    },
    hideWlansLengthError() {
      this.isWlansLengthErrorShowing = false;
    },
    showAllZerosInSpecEveryDurationObjectError() {
      this.isAllZerosInSpecEveryDurationObjectErrorShowing = true;
    },
    hideAllZerosInSpecEveryDurationObjectError() {
      this.isAllZerosInSpecEveryDurationObjectErrorShowing = false;
    },
    cutOffLongTextForCPEDescription(text) {
      try {
        if (text.length > 60) {
          return `${text.slice(0, 60)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    clearValidatorErrors() {
      this.errors.clear();
      this.hideCpesLengthError();
      this.hideWlansLengthError();
      this.hideAllZerosInSpecEveryDurationObjectError();
    },
    createPeriodicTask() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.selectedCpesCount === 0 && this.isRebootPointActionTypeSelected) {
              this.showCpesLengthError();
            } else if (this.selectedWlansCount === 0 && this.isChangePasswordActionTypeSelected) {
              this.showWlansLengthError();
            } else if (this.checkIsAllZerosInSpecEveryDurationObjectForModal() === true) {
              this.showAllZerosInSpecEveryDurationObjectError();
            } else {
              if (this.isRebootPointActionTypeSelected) {
                // наполняем объект периодической задачи idшниками cpe, выбраннами в селекторе CPE, чтобы сохранить их
                const cpesIds = this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.map((cpe) => cpe.id);
                this.updatedOrNewPeriodicTask.ids = cpesIds;
              } else if (this.isChangePasswordActionTypeSelected) {
                // наполняем объект периодической задачи idшниками wlan, выбраннами в селекторе CPE, чтобы сохранить их
                const wlansIds = this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.map(
                  (wlan) => wlan.id
                );
                this.updatedOrNewPeriodicTask.ids = wlansIds;
              }

              // апдейтим
              // console.log(this.updatedOrNewPeriodicTask)
              PeriodicTasksService.createPeriodicTask(this);
            }
          } else {
            if (this.selectedCpesCount === 0 && this.isRebootPointActionTypeSelected) {
              this.showCpesLengthError();
            }
            if (this.selectedWlansCount === 0 && this.isChangePasswordActionTypeSelected) {
              this.showWlansLengthError();
            }
            if (this.checkIsAllZerosInSpecEveryDurationObjectForModal() === true) {
              this.showAllZerosInSpecEveryDurationObjectError();
            }
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    updatePeriodicTask() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.selectedCpesCount === 0 && this.isRebootPointActionTypeSelected) {
              this.showCpesLengthError();
            } else if (this.selectedWlansCount === 0 && this.isChangePasswordActionTypeSelected) {
              this.showWlansLengthError();
            } else if (this.checkIsAllZerosInSpecEveryDurationObjectForModal() === true) {
              this.showAllZerosInSpecEveryDurationObjectError();
            } else {
              if (this.isRebootPointActionTypeSelected) {
                // наполняем объект периодической задачи idшниками cpe, выбраннами в селекторе CPE, чтобы сохранить их
                const cpesIds = this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.map((cpe) => cpe.id);
                this.updatedOrNewPeriodicTask.ids = cpesIds;
              } else if (this.isChangePasswordActionTypeSelected) {
                // наполняем объект периодической задачи idшниками wlan, выбраннами в селекторе CPE, чтобы сохранить их
                const wlansIds = this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.map(
                  (wlan) => wlan.id
                );
                this.updatedOrNewPeriodicTask.ids = wlansIds;
              }
              // апдейтим
              // console.log(this.updatedOrNewPeriodicTask)
              PeriodicTasksService.updatePeriodicTask(this);
            }
          } else {
            if (this.selectedCpesCount === 0 && this.isRebootPointActionTypeSelected) {
              this.showCpesLengthError();
            }
            if (this.selectedWlansCount === 0 && this.isChangePasswordActionTypeSelected) {
              this.showWlansLengthError();
            }
            if (this.checkIsAllZerosInSpecEveryDurationObjectForModal() === true) {
              this.showAllZerosInSpecEveryDurationObjectError();
            }
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    deletePeriodicTask() {
      // console.log('delete')
      PeriodicTasksService.deletePeriodicTask(this);
    },
    closeDeletePeriodicTaskModal() {
      this.isdeletingPeriodicTasktModalShowing = false;
    },
    openDeletePeriodicTaskModal() {
      this.isdeletingPeriodicTasktModalShowing = true;
    },
    cpesCount(cpeIds) {
      try {
        return cpeIds.length;
      } catch (e) {
        return 0;
      }
    },
    wlansCount(wlansIds) {
      try {
        return wlansIds.length;
      } catch (e) {
        return 0;
      }
    },
    tooltipForPeriodicTaskItemCpeColumnInTable(cpeIds) {
      const cpes = this.cpeObjectsForPeriodicTask(cpeIds);
      let tooltip = `<div class='text-left m-0'>${this.$t('PeriodicTasks.tableTooltipCPEs')}:</div>`;
      tooltip += '<ul class=\'list-unstyled text-left m-0\'>';
      for (const cpe of cpes) {
        tooltip += `<li><small>${cpe.name}</small></li>`;
      }
      tooltip += '</ul>';

      return tooltip;
    },
    tooltipForPeriodicTaskItemWlansColumnInTable(wlanIds) {
      const wlans = this.wlanObjectsForPeriodicTask(wlanIds);
      let tooltip = `<div class='text-left m-0'>${this.$t('PeriodicTasks.tableTooltipWlans')}:</div>`;
      tooltip += '<ul class=\'list-unstyled text-left m-0\'>';
      for (const wlan of wlans) {
        tooltip += `<li><small>${wlan.ssid}</small></li>`;
      }
      tooltip += '</ul>';

      return tooltip;
    },
    cpeObjectsForPeriodicTask(cpeIds) {
      try {
        const result = [];
        const cpesIdsWithNODataAboutCpe = JSON.parse(JSON.stringify(cpeIds));
        for (const cpe of this.cpesList) {
          if (cpeIds.includes(cpe.id)) {
            result.push(cpe);
            const index = cpesIdsWithNODataAboutCpe.indexOf(cpe.id);
            if (index >= 0) {
              cpesIdsWithNODataAboutCpe.splice(index, 1);
            }
            // console.log(cpesIdsWithNODataAboutCpe)
          }
        }
        if (cpesIdsWithNODataAboutCpe.length > 0) {
          // если так получилось то какая-то из точек доступа, ID которой указан для выгрузки в
          // данный момент не найдена в API точек
          // создаем для такой точки объект с ID вместо имени и ID
          for (const cpeid of cpesIdsWithNODataAboutCpe) {
            result.push({ id: cpeid, name: cpeid });
          }
        }
        return result;
      } catch (e) {
        return [];
      }
    },

    wlanObjectsForPeriodicTask(wlanIds) {
      try {
        const result = [];
        const wlansIdsWithNODataAboutWlan = JSON.parse(JSON.stringify(wlanIds));
        for (const wlan of this.wlansList) {
          if (wlanIds.includes(wlan.id)) {
            result.push(wlan);
            const index = wlansIdsWithNODataAboutWlan.indexOf(wlan.id);
            if (index >= 0) {
              wlansIdsWithNODataAboutWlan.splice(index, 1);
            }
            // console.log(wlansIdsWithNODataAboutWlan)
          }
        }
        if (wlansIdsWithNODataAboutWlan.length > 0) {
          // если так получилось то какая-то из точек доступа, ID которой указан для выгрузки в
          // данный момент не найдена в API точек
          // создаем для такой точки объект с ID вместо имени и ID
          for (const wlanid of wlansIdsWithNODataAboutWlan) {
            result.push({ id: wlanid, ssid: wlanid });
          }
        }
        return result;
      } catch (e) {
        return [];
      }
    },

    getActionTypeForTable(periodicTask) {
      try {
        if (periodicTask.notify === undefined) {
          return '';
        }
        return this.$t(`PeriodicTasks.actionType.${periodicTask.action}`);
      } catch (e) {
        console.log(e);
        return periodicTask.action;
      }
    },
    getNotifyTypeForTable(periodicTask) {
      try {
        if (periodicTask.notify === undefined) {
          return '';
        }
        return this.$t(`PeriodicTasks.notifyType.${periodicTask.notify}`);
      } catch (e) {
        console.log(e);
        return periodicTask.notify;
      }
    },
    selectNotifyTypeHandler(event) {
      this.clearNotifySettings();
      const selectedNotifyType = event.target.value;
      if (selectedNotifyType === 'telegram') {
        // console.log('telegram selected');
        this.createNotifySettingsObjectForTelegram();
      } else if (selectedNotifyType === 'email') {
        // console.log('email selected');
        this.createNotifySettingsObjectForEmail();
      } else if (selectedNotifyType === 'mqtt') {
        // console.log('mqtt selected');
        this.createNotifySettingsObjectForMQTT();
      } else {
        // console.log('script selected');
      }
    },
    selectSpecTypeHandler(event) {
      const selectedSpecType = event.target.value;
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('periodic-task-spec-raw')) {
          this.errors.remove('periodic-task-spec-raw');
        }
      }
      this.initSpecFieldInupdatedOrNewPeriodicTaskDependingOnSelectedSpecType(selectedSpecType);
    },
    initSpecFieldInupdatedOrNewPeriodicTaskDependingOnSelectedSpecType(selectedSpecType) {
      // console.log(selectedSpecType)
      this.specTypeForModal = selectedSpecType;
      if (selectedSpecType === '@hourly') {
        this.updatedOrNewPeriodicTask.spec = '@hourly';
      } else if (selectedSpecType === '@daily') {
        this.updatedOrNewPeriodicTask.spec = '@daily';
      } else if (selectedSpecType === '@weekly') {
        this.updatedOrNewPeriodicTask.spec = '@weekly';
      } else if (selectedSpecType === '@monthly') {
        this.updatedOrNewPeriodicTask.spec = '@monthly';
      } else if (selectedSpecType === '@yearly') {
        this.updatedOrNewPeriodicTask.spec = '@yearly';
      } else if (selectedSpecType === '@every') {
        this.updatedOrNewPeriodicTask.spec = '';
        this.clearSpecEveryDurationObjectForModal();
        this.fillSpecFieldWhenEditSpecTypeEveryFields();
      } else if (selectedSpecType === 'raw') {
        this.updatedOrNewPeriodicTask.spec = '';
      }
    },
    clearNotifySettings() {
      this.updatedOrNewPeriodicTask.notify_settings = null;
    },
    createNotifySettingsObjectForTelegram() {
      this.$set(this.updatedOrNewPeriodicTask, 'notify_settings', {});
      this.updatedOrNewPeriodicTask.notify_settings = {
        ...this.updatedOrNewPeriodicTask.notify_settings,
        token: '',
        chat_id: '',
        text: ''
      };
    },
    createNotifySettingsObjectForEmail() {
      this.$set(this.updatedOrNewPeriodicTask, 'notify_settings', {});
      this.updatedOrNewPeriodicTask.notify_settings = {
        ...this.updatedOrNewPeriodicTask.notify_settings,
        to: '',
        subject: '',
        text: ''
      };
    },
    createNotifySettingsObjectForMQTT() {
      this.$set(this.updatedOrNewPeriodicTask, 'notify_settings', {});
      this.updatedOrNewPeriodicTask.notify_settings = {
        ...this.updatedOrNewPeriodicTask.notify_settings,
        topic: '',
        text: ''
      };
    },
    handleModalNameFieldInput() {
      // console.log('input')
      // очищаем ошибку поля periodic-task-name, если она есть, каждый раз когда начанаем вводить в periodic-task-name
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('periodic-task-name')) {
          this.errors.remove('periodic-task-name');
        }
      }
    },
    handleModalSpecFieldInput() {
      // console.log('input')
      // очищаем ошибку поля periodic-task-spec, если она есть, каждый раз когда начанаем вводить в periodic-task-spec
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('periodic-task-spec')) {
          this.errors.remove('periodic-task-spec');
        }
      }
    },
    handleModalSpecFieldRawInput() {
      // console.log('input')
      // очищаем ошибку поля periodic-task-spec, если она есть, каждый раз когда начанаем вводить в periodic-task-spec
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('periodic-task-spec-raw')) {
          this.errors.remove('periodic-task-spec-raw');
        }
      }
    },
    clearValidatorError(errorName) {
      // console.log(errorName)
      // очищаем ошибку errorName, если она есть
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    notifySettingsTelegramTokenInputHandler() {
      this.clearValidatorError('notifySettingsTelegramToken');
    },
    notifySettingsTelegramChatIdInputHandler() {
      this.clearValidatorError('notifySettingsTelegramChatId');
    },
    notifySettingsTelegramTextInputHandler() {
      this.clearValidatorError('notifySettingsTelegramText');
    },
    notifySettingsEmailToInputHandler() {
      this.clearValidatorError('notifySettingsEmailTo');
    },
    notifySettingsEmailSubjectInputHandler() {
      this.clearValidatorError('notifySettingsEmailSubject');
    },
    notifySettingsEmailTextInputHandler() {
      this.clearValidatorError('notifySettingsEmailText');
    },
    notifySettingsMQTTTopicInputHandler() {
      this.clearValidatorError('notifySettingsMQTTTopic');
    },
    notifySettingsMQTTTextInputHandler() {
      this.clearValidatorError('notifySettingsMQTTText');
    },
    specEveryTypeDaysInputHandler() {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('specEveryTypeDaysInput')) {
          this.errors.remove('specEveryTypeDaysInput');
        }
      }
      this.fillSpecFieldWhenEditSpecTypeEveryFields();
    },
    specEveryTypeHoursInputHandler() {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('specEveryTypeHoursInput')) {
          this.errors.remove('specEveryTypeHoursInput');
        }
      }
      this.fillSpecFieldWhenEditSpecTypeEveryFields();
    },
    specEveryTypeMinutesInputHandler() {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('specEveryTypeMinutesInput')) {
          this.errors.remove('specEveryTypeMinutesInput');
        }
      }
      this.fillSpecFieldWhenEditSpecTypeEveryFields();
    },
    specEveryTypeSecondsInputHandler() {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('specEveryTypeSecondsInput')) {
          this.errors.remove('specEveryTypeSecondsInput');
        }
      }
      this.fillSpecFieldWhenEditSpecTypeEveryFields();
    },
    parseSpecField(spec) {
      // если возвращаем return { type: 'raw' } значит не смогли распарсить строчку.
      // (чтобы потом показывать ее в UI как есть)
      try {
        if (spec.split(' ').length === 2) {
          // console.log(spec.split(' '))
          if (spec.split(' ')[0] === '@every') {
            const parsedEveryDuration = this.parseSpecEveryStringDuration(spec.split(' ')[1]);
            return { type: '@every', duration: parsedEveryDuration };
          }
          return { type: 'raw' };
        }
        if (spec === '@hourly') {
          return { type: '@hourly' };
        } if (spec === '@daily') {
          return { type: '@daily' };
        } if (spec === '@weekly') {
          return { type: '@weekly' };
        } if (spec === '@monthly') {
          return { type: '@monthly' };
        } if (spec === '@yearly') {
          return { type: '@yearly' };
        }
        return { type: 'raw' };
      } catch (e) {
        console.log(e);
        return { type: 'raw' };
      }
    },

    fillSpecFieldWhenEditSpecTypeEveryFields() {
      this.hideAllZerosInSpecEveryDurationObjectError();
      // вызываетсся при заполнении полей дени, часы, минуты, секунды  и заполняет поле spec в
      // нужном формате
      try {
        // тут нужно перевести дни в часы (потому что формат дней не валидный для поля spec )
        // https://pkg.go.dev/time#ParseDuration
        // Valid time units are "ns", "us" (or "µs"), "ms", "s", "m", "h"

        let days = 0;
        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        if (
          Object.prototype.hasOwnProperty.call(this.specEveryDurationObjectForModal, 'days') &&
          this.specEveryDurationObjectForModal.days !== ''
        ) {
          days = this.specEveryDurationObjectForModal.days;
        }

        if (
          Object.prototype.hasOwnProperty.call(this.specEveryDurationObjectForModal, 'hours') &&
          this.specEveryDurationObjectForModal.hours !== ''
        ) {
          hours = this.specEveryDurationObjectForModal.hours + days * 24;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.specEveryDurationObjectForModal, 'minutes') &&
          this.specEveryDurationObjectForModal.minutes !== ''
        ) {
          minutes = this.specEveryDurationObjectForModal.minutes;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.specEveryDurationObjectForModal, 'seconds') &&
          this.specEveryDurationObjectForModal.seconds !== ''
        ) {
          seconds = this.specEveryDurationObjectForModal.seconds;
        }
        const resultString = `@every ${hours}h${minutes}m${seconds}s`;
        this.updatedOrNewPeriodicTask.spec = resultString;
      } catch (e) {
        console.log(e);
        this.updatedOrNewPeriodicTask.spec = '@every 720h0m0s'; // если почему то не смогли заполнить ставим месяц
      }
    },
    checkIsAllZerosInSpecEveryDurationObjectForModal() {
      try {
        if (
          this.specTypeForModal === '@every' &&
          Object.prototype.hasOwnProperty.call(this.specEveryDurationObjectForModal, 'days') &&
          this.specEveryDurationObjectForModal.days === 0 &&
          Object.prototype.hasOwnProperty.call(this.specEveryDurationObjectForModal, 'hours') &&
          this.specEveryDurationObjectForModal.hours === 0 &&
          Object.prototype.hasOwnProperty.call(this.specEveryDurationObjectForModal, 'minutes') &&
          this.specEveryDurationObjectForModal.minutes === 0 &&
          Object.prototype.hasOwnProperty.call(this.specEveryDurationObjectForModal, 'seconds') &&
          this.specEveryDurationObjectForModal.seconds === 0
        ) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    getSpecFieldForDIsplayInTableAsStringWithI18N(spec) {
      try {
        const parsedObject = this.parseSpecField(spec);
        if (parsedObject.type === '@every') {
          return `${this.$t('PeriodicTasks.period')}: ${this.parseParsedEveryObjDurationToStringWithI18NForTable(
            parsedObject.duration
          )}`;
        } if (parsedObject.type === '@hourly') {
          return `${this.$t(`PeriodicTasks.specFieldCaption${parsedObject.type}`)}`;
        } if (parsedObject.type === '@daily') {
          return `${this.$t(`PeriodicTasks.specFieldCaption${parsedObject.type}`)}`;
        } if (parsedObject.type === '@weekly') {
          return `${this.$t(`PeriodicTasks.specFieldCaption${parsedObject.type}`)}`;
        } if (parsedObject.type === '@monthly') {
          return `${this.$t(`PeriodicTasks.specFieldCaption${parsedObject.type}`)}`;
        } if (parsedObject.type === '@yearly') {
          return `${this.$t(`PeriodicTasks.specFieldCaption${parsedObject.type}`)}`;
        }
        return `${this.$t('PeriodicTasks.specFieldCaptionraw')}: ${spec}`;
      } catch (e) {
        console.log(e);
        return spec;
      }
    },
    getSpecFieldInfoForDIsplayInTableAsStringWithI18N(spec) {
      try {
        const parsedObject = this.parseSpecField(spec);
        if (parsedObject.type === '@every') {
          return '';
        } if (parsedObject.type === '@hourly') {
          return `${this.$t(`PeriodicTasks.specFieldInfo${parsedObject.type}`)}`;
        } if (parsedObject.type === '@daily') {
          return `${this.$t(`PeriodicTasks.specFieldInfo${parsedObject.type}`)}`;
        } if (parsedObject.type === '@weekly') {
          return `${this.$t(`PeriodicTasks.specFieldInfo${parsedObject.type}`)}`;
        } if (parsedObject.type === '@monthly') {
          return `${this.$t(`PeriodicTasks.specFieldInfo${parsedObject.type}`)}`;
        } if (parsedObject.type === '@yearly') {
          return `${this.$t(`PeriodicTasks.specFieldInfo${parsedObject.type}`)}`;
        }
        return `${this.$t('PeriodicTasks.specFieldInforaw')}: ${spec}`;
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    parseParsedEveryObjDurationToStringWithI18NForTable(parsedEveryObjDuration) {
      let resultString = '';
      if (Object.prototype.hasOwnProperty.call(parsedEveryObjDuration, 'days') && parsedEveryObjDuration.days !== 0) {
        resultString += `${parsedEveryObjDuration.days} ${this.$t('PeriodicTasks.daysShort')}.  `;
      }
      if (Object.prototype.hasOwnProperty.call(parsedEveryObjDuration, 'hours') && parsedEveryObjDuration.hours !== 0) {
        resultString += `${parsedEveryObjDuration.hours} ${this.$t('PeriodicTasks.hoursShort')}.  `;
      }
      if (
        Object.prototype.hasOwnProperty.call(parsedEveryObjDuration, 'minutes') &&
        parsedEveryObjDuration.minutes !== 0
      ) {
        resultString += `${parsedEveryObjDuration.minutes} ${this.$t('PeriodicTasks.minutesShort')}.  `;
      }
      if (
        Object.prototype.hasOwnProperty.call(parsedEveryObjDuration, 'seconds') &&
        parsedEveryObjDuration.seconds !== 0
      ) {
        resultString += `${parsedEveryObjDuration.seconds} ${this.$t('PeriodicTasks.secondsShort')}. `;
      }
      return resultString;
    },
    parseSpecEveryStringDuration(everyFieldTimes) {
      // парсим продолжительность, из строк вида @every (https://pkg.go.dev/time#ParseDuration)
      // там могут быть "ns", "us" (or "µs"), "ms", "s", "m", "h".
      // но перводим в секунды, минуты, часы и для удобства в еще дни
      // console.log(everyFieldTimes);
      const resultObject = {
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
      };
      const everyFieldDurationObject = moment.duration(`PT${everyFieldTimes}`.toUpperCase());
      // console.log(everyFieldDurationObject.seconds())
      // console.log(everyFieldDurationObject.minutes())
      // console.log(everyFieldDurationObject.hours())
      // console.log(everyFieldDurationObject.asDays())
      resultObject.seconds = everyFieldDurationObject.seconds();
      resultObject.minutes = everyFieldDurationObject.minutes();
      resultObject.hours = everyFieldDurationObject.hours();
      resultObject.days = Math.floor(everyFieldDurationObject.asDays()); // округляем в меньшую сторну, чтобы точно получить количество дней
      return resultObject;
    },
    clearSpecEveryDurationObjectForModal() {
      this.specEveryDurationObjectForModal = {
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
      };
    },
    periodicTasksActionTypeFilter(PeriodicTasksArray, filterValue) {
      if (!PeriodicTasksArray) {
        return [];
      }
      const PeriodicTasksArrayFiltredByActionType = JSON.parse(JSON.stringify(PeriodicTasksArray));
      if (filterValue !== 'change_password' && filterValue !== 'reboot_point') {
        return PeriodicTasksArrayFiltredByActionType;
      }
      if (filterValue === 'change_password') {
        return PeriodicTasksArrayFiltredByActionType.filter((item) => {
          if (item.action === 'change_password') {
            return true;
          }
          return false;
        });
      }
      if (filterValue === 'reboot_point') {
        return PeriodicTasksArrayFiltredByActionType.filter((item) => {
          if (item.action === 'reboot_point') {
            return true;
          }
          return false;
        });
      }
      return [];
    },
    filterItems(periodicTasksList) {
      try {
        this.$store.commit('filteringPeriodicTasksBegin');
        let filteredPeriodicTasks = JSON.parse(JSON.stringify(periodicTasksList));
        filteredPeriodicTasks = this.periodicTasksActionTypeFilter(filteredPeriodicTasks, this.filters.actionType);
        const unsortedPeriodicTasksList = this.PeriodicTasksSearch(filteredPeriodicTasks, this.filters.query);
        let sortedPeriodicTasksList = [];
        // sortedPeriodicTasksList = unsortedPeriodicTasksList.sort((curr, prev) => {
        //   if (curr.name > prev.name) {
        //     return 1;
        //   }
        //   if (curr.name < prev.name) {
        //     return -1;
        //   }
        //   return 0;
        // });

        let first;
        let second;
        if (this.sortMode === 'desc') {
          first = -1;
          second = 1;
        } else if (this.sortMode === 'asc') {
          first = 1;
          second = -1;
        }

        if (this.sortByFilter === 'Name') {
          sortedPeriodicTasksList = unsortedPeriodicTasksList.sort((curr, prev) => {
            if (curr.name.toLowerCase() > prev.name.toLowerCase()) {
              return first;
            }
            if (curr.name.toLowerCase() < prev.name.toLowerCase()) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'ActionType') {
          sortedPeriodicTasksList = unsortedPeriodicTasksList.sort((curr, prev) => {
            const prevActionType = prev.action.toLowerCase();
            const currActionType = curr.action.toLowerCase();
            if (currActionType > prevActionType) {
              return first;
            }
            if (currActionType < prevActionType) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'ObjectsCount') {
          sortedPeriodicTasksList = unsortedPeriodicTasksList.sort((curr, prev) => {
            let prevActionCpeCount = 0;
            let currActionCpeCount = 0;
            if (Array.isArray(prev.ids)) {
              prevActionCpeCount = prev.ids.length;
            }
            if (Array.isArray(curr.ids)) {
              currActionCpeCount = curr.ids.length;
            }
            if (currActionCpeCount > prevActionCpeCount) {
              return first;
            }
            if (currActionCpeCount < prevActionCpeCount) {
              return second;
            }
            return 0;
          });
        } else if (this.sortByFilter === 'NotifyType') {
          sortedPeriodicTasksList = unsortedPeriodicTasksList.sort((curr, prev) => {
            const prevNotifyType = prev.notify.toLowerCase();
            const currNotifyType = curr.notify.toLowerCase();
            if (currNotifyType > prevNotifyType) {
              return first;
            }
            if (currNotifyType < prevNotifyType) {
              return second;
            }
            return 0;
          });
        }

        this.$store.commit('filteringPeriodicTasksEnd');
        return sortedPeriodicTasksList;
      } catch (e) {
        this.$store.commit('filteringPeriodicTasksEnd');
        console.log(e);
        return periodicTasksList;
      }
    },
    PeriodicTasksSearch(PeriodicTasksArray, searchQuery) {
      if (!PeriodicTasksArray) {
        return [];
      }
      const PeriodicTasksArrayFiltred = JSON.parse(JSON.stringify(PeriodicTasksArray));
      return PeriodicTasksArrayFiltred.filter((item) => {
        if (item.hasOwnProperty('id') && item.id && item.id.toLowerCase().includes(searchQuery.toLowerCase())) {
          return true;
        }
        if (item.hasOwnProperty('name') && item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          return true;
        }
        if (
          item.hasOwnProperty('notify') &&
          item.nas_ip &&
          item.nas_ip.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        if (
          item.hasOwnProperty('action') &&
          item.client &&
          item.client.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        if (searchQuery) {
          return null;
        }
        return item;
      });
    },
    toggleSortMode() {
      if (this.sortMode === 'desc') {
        this.sortMode = 'asc';
      } else {
        this.sortMode = 'desc';
      }
    },
    searchClear() {
      this.filters.query = '';
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters.query = '';
    //   this.filters.actionType = 'All';
    //   this.sortByFilter = 'Name';
    //   this.filters.location = 'All locations';
    //   this.filters.with_childs = true;
    //   this.getAllPeriodicTasks(this);
    // }
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters.query = '';
      this.filters.actionType = 'All';
      this.sortByFilter = 'Name';
      // this.filters.location = 'All locations';
      // this.filters.with_childs = true;
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = true;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();

      this.getAllPeriodicTasks(this);
    }
  },
  watch: {
    periodicTasksList: {
      // смотрим за объектом, котрый обновляется из функции getCpeLatestStats в statCpeLatestService.js
      // как только обновился, прпихиваем новые серии, взятые оттуда в графики
      deep: true,
      handler(val) {
        this.getCompactCpesAndWlansByIDsFromWatcher(val)
      }
    }
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    showSpinnerOverCPEsFilterInModal() {
      return this.cpesListFromReqWithIDsLoading || this.loadingDataForCpeListLength || this.loadingAllCpesForLocation
    },
    showSpinnerOverWlansFilterInModal() {
      return this.wlansListFromReqWithIDsLoading || this.loadingDataForWlanListLength || this.loadingAllWlansForLocation
    },
    locationIdForCpeSearchRequests() {
      try {
        if (this.filtersForCpeList.location && this.filtersForCpeList.location !== 'All locations') {
          return this.filtersForCpeList.location;
        }
        return undefined;
      } catch (e) {
        console.log(e);
        return undefined

      }
    },
    locationIdForWlanSearchRequests() {
      try {
        if (this.filtersForWlanList.location && this.filtersForWlanList.location !== 'All locations') {
          return this.filtersForWlanList.location;
        }
        return undefined;
      } catch (e) {
        console.log(e);
        return undefined

      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isChangePasswordActionTypeSelected() {
      try {
        if (this.updatedOrNewPeriodicTask.action === 'change_password') {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    isFiltersActive(){
      return this.filters.query !== '' || this.filters.actionType !== 'All' ||
        this.sortByFilter !== 'Name' || this.filters.location !== 'All locations'
    },
    isRebootPointActionTypeSelected() {
      try {
        if (this.updatedOrNewPeriodicTask.action === 'reboot_point') {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    PERIODIC_TASK_ACTION_TYPES() {
      return PERIODIC_TASK_ACTION_TYPES;
    },
    PERIODIC_TASK_NOTIFICATION_TYPES() {
      return PERIODIC_TASK_NOTIFICATION_TYPES;
    },
    PERIODIC_TASK_SPEC_FIELD_TYPES() {
      return PERIODIC_TASK_SPEC_FIELD_TYPES;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    // isShowOverTableSpinner() {
    //   return (
    //     this.isloadingPeriodicTaskList ||
    //     this.isLoadingCpesList ||
    //     this.isLoadingLocationsList ||
    //     this.isLoadingWlansList ||
    //     this.isFilteringPeriodicTasks
    //   );
    // },
    isShowOverTableSpinner() {
      return (
        this.isloadingPeriodicTaskList ||
        // this.isLoadingCpesList ||
        this.isLoadingLocationsList ||
        this.isLoadingWlansList ||
        this.isFilteringPeriodicTasks ||
        this.cpesListFromReqWithIDsLoading || this.wlansListFromReqWithIDsLoading
      );
    },
    isloadingPeriodicTaskList() {
      return this.$store.state.loadingPeriodicTasksList;
    },
    isLoadingCpesList() {
      return this.$store.state.loadingCpes;
    },
    isLoadingWlansList() {
      return this.$store.state.loadingWlans;
    },
    isLoadingLocationsList() {
      return this.$store.state.loadingLocations;
    },
    isFilteringPeriodicTasks() {
      return this.$store.state.filteringPeriodicTasks;
    },
    isShowOverCreateOrEditMOdalSpinner() {
      return (
        this.isUpdatePeriodicTaskReqExecuting ||
        this.isCreatePeriodicTaskReqExecuting ||
        this.isDeletePeriodicTaskReqExecuting
      );
    },
    isUpdatePeriodicTaskReqExecuting() {
      return this.$store.state.updatePeriodicTaskReqExecuting;
    },
    isCreatePeriodicTaskReqExecuting() {
      return this.$store.state.createPeriodicTaskReqExecuting;
    },
    isDeletePeriodicTaskReqExecuting() {
      return this.$store.state.deletePeriodicTaskReqExecuting;
    },
    isCpesListFiltering() {
      return this.$store.state.filteringCpeListInPeriodicTasksModalWindow;
    },
    isWlansListFiltering() {
      return this.$store.state.filteringWlanListInPeriodicTasksModalWindow;
    },
    cpesList() {
      // return this.$store.state.cpesList;
      return this.cpesListFromReqWithIDs
    },
    wlansList() {
      // return this.$store.state.wlansList;
      return this.wlansListFromReqWithIDs
    },
    // locationsList() {
    //   return this.$store.getters.locationsListSorted;
    // },
    locationsList() {
      if (this.isSelectedDefaultLocationForRequests) {
        return this.$store.getters.locationsListSortedOnlySelectedInHeaderLocationAndChilsdsIfEnabled
      } else{
        return this.$store.getters.locationsListSorted;
      }
    },

    // filterdCpesListForModal() {
    //   this.$store.commit('filteringCpeListInPeriodicTasksModalWindowStart');
    //   try {
    //     let filtredCpes = JSON.parse(JSON.stringify(this.cpesList));
    //     if (this.filtersForCpeList.isFiltersForCpeListEnabled) {
    //       if (this.filtersForCpeList.location !== 'All locations') {
    //         filtredCpes = filtredCpes.filter((cpe) => cpe.base_location === this.filtersForCpeList.location);
    //       }
    //     }
    //     this.$store.commit('filteringCpeListInPeriodicTasksModalWindowEnd');
    //
    //     if (this.showDescriptionInCpesFilter === true) {
    //       for (const cpeIndex in filtredCpes) {
    //         if (filtredCpes[cpeIndex].description !== '') {
    //           filtredCpes[
    //             cpeIndex
    //           ].name = `${filtredCpes[cpeIndex].name}${this.charactersForCpeListSeparateNameFromDescription} ${filtredCpes[cpeIndex].description}`;
    //         } else {
    //           filtredCpes[cpeIndex].name = `${filtredCpes[cpeIndex].name}`;
    //         }
    //       }
    //     }
    //
    //     return filtredCpes;
    //   } catch (e) {
    //     console.log(e);
    //     this.$store.commit('filteringCpeListInDITExportModalWindowEnd');
    //     return this.cpesList;
    //   }
    // },

    filterdWlansListForModal() {
      this.$store.commit('filteringWlanListInPeriodicTasksModalWindowStart');
      try {
        let filtredWlans = JSON.parse(JSON.stringify(this.wlansList));
        if (this.filtersForWlanList.isFiltersForWlanListEnabled) {
          if (this.filtersForWlanList.location !== 'All locations') {
            filtredWlans = filtredWlans.filter((wlan) => wlan.base_location === this.filtersForWlanList.location);
          }
        }
        this.$store.commit('filteringWlanListInPeriodicTasksModalWindowEnd');

        // if (this.showDescriptionInCpesFilter === true) {
        //   for (const cpeIndex in filtredWlans) {
        //     if (filtredWlans[cpeIndex].description !== '') {
        //       filtredWlans[cpeIndex].name = `${filtredWlans[cpeIndex].name}${this.charactersForCpeListSeparateNameFromDescription} ${filtredWlans[cpeIndex].description}`;
        //     } else {
        //       filtredWlans[cpeIndex].name = `${filtredWlans[cpeIndex].name}`;
        //     }
        //   }
        // }

        return filtredWlans;
      } catch (e) {
        console.log(e);
        this.$store.commit('filteringWlanListInPeriodicTasksModalWindowEnd');
        return this.wlansList;
      }
    },

    isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled() {
      // если в выпадающих списках что-то выбрано и фильтрация включена, выводим true, чтобы потом
      // показать плашку о том что филтрация включена в UI
      if (this.filtersForCpeList.isFiltersForCpeListEnabled && this.filtersForCpeList.location !== 'All locations') {
        return true;
      }
      return false;
    },

    isSelectedNoDefaultsValuesInFiltersForWlanListANDFiltringEnabled() {
      // если в выпадающих списках что-то выбрано и фильтрация включена, выводим true, чтобы потом
      // показать плашку о том что филтрация включена в UI
      if (this.filtersForWlanList.isFiltersForWlanListEnabled && this.filtersForWlanList.location !== 'All locations') {
        return true;
      }
      return false;
    },

    selectedCpesCount() {
      try {
        if (Array.isArray(this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector)) {
          return this.cpesObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.length;
        }
        return 0;
      } catch (e) {
        return 0;
      }
    },
    selectedWlansCount() {
      try {
        if (Array.isArray(this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector)) {
          return this.wlansObjectsForUpdatedOrNewPeriodicTaskSelectedInCPESelector.length;
        }
        return 0;
      } catch (e) {
        return 0;
      }
    },
    isShowOverDeleteModalSpinner() {
      return this.isDeletePeriodicTaskReqExecuting;
    },
    computedFilteredItems() {
      return this.filterItems(this.periodicTasksList);
    },
    currSortMode() {
      return this.sortMode;
    }
  },
  // created() {
  //   this.getAllPeriodicTasks(this);
  //   this.getCompactCpesList();
  //   this.getLocations();
  //   this.getCompactWlansList();
  // }

  created() {

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    this.getAllPeriodicTasks(this);
    // this.getCompactCpesList();
    this.getLocations();
    // this.getCompactWlansList();

  }

};
</script>

<style scoped>
.periodic-tasks-notify-params-to-in-column {
  font-weight: 600;
}
.max-width-column-periodic-tasks-table {
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}
.showing-filters-for-cpe-list-block-button {
  margin-bottom: -1.2em;
}
.action-type-block-in-periodic-task-modal {
  /*position: relative;*/
  /*top: -1em*/
}

.spec-type-block-in-periodic-task-modal {
  position: relative;
  top: -1em;
}

.field-wrapper-for-spec-every-input {
  width: 170px;
}
.field-for-spec-type-every-fields {
  text-align: right;
}
</style>
<style>
            .input-group-for-location-select-in-monitoringPeriodicTasks--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-monitoringPeriodicTasks--filters .select__list-container {
    width: 400px;
  }




.periodicTasksModalTransition-enter-active,
.periodicTasksModalTransition-leave-active {
  transition: opacity 0.3s;
}

.periodicTasksModalTransition-enter,
.periodicTasksModalTransition-leave-to {
  opacity: 0;
}

.fadeforcpefiltersblockinPeriodicTasksBlock-enter-active,
.fadeforcpefiltersblockinPeriodicTasksBlock-leave-active {
  transition: opacity 0.7s;
}

.fadeforcpefiltersblockinPeriodicTasksBlock-enter, .fadeforcpefiltersblockinPeriodicTasksBlock-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.multiselect-max-height-in-periodic-tasks-modal .multiselect__tags {
  max-height: 90px !important;
  padding: 7px !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.multiselect-max-height-in-periodic-tasks-modal .multiselect__tags::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f1f1f1;
}

.multiselect-max-height-in-periodic-tasks-modal .multiselect__tags::-webkit-scrollbar-thumb {
  background: lightgray;
}
</style>
